
import {Options, Vue} from "vue-class-component";

@Options({
    props: ['show', 'details']
})
export default class ObjectDetailBar extends Vue {
    show!: boolean
    details!: any
    
    get showProp(): boolean {
        return this.show
    }
    
    get detailsProp(): any {
        return this.details
    }
    
    close() {
        this.$emit('close')
    }
}


import { Vue } from "vue-class-component";
import {getProjectByIdRequest} from "@/services/ObjectService";

export default class ObjectDetail extends Vue {
    
    headline = 'Objektdetails'
    details: any = {}
    
    
    created() {
        getProjectByIdRequest(this.$route.params.id).then((res: any) => {
            this.details = res.result.details
            this.details.projectName = res.result.details?.p_firma || res.result.details?.p_vorname+' '+res.result.details?.p_name || ''
        })
    }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "font-weight-bold mb-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "text-uppercase font-size-sm font-weight-bold d-flex flex-row align-items-center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.headline), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locationMaps, (map, mIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: mIndex,
          class: "col-12 col-md-6 col-xl-4"
        }, [
          _createElementVNode("legend", _hoisted_4, _toDisplayString(map.lageplanName), 1),
          _createElementVNode("a", {
            class: "cursor-pointer",
            onClick: ($event: any) => (_ctx.showLocationMap(map.target, map.lageplanName))
          }, [
            _createElementVNode("img", {
              src: map.target,
              class: "w-100"
            }, null, 8, _hoisted_6)
          ], 8, _hoisted_5)
        ]))
      }), 128))
    ])
  ]))
}
import { createRouter, createWebHistory } from 'vue-router'
// import store from './../store'
import { routes as basicRoutes } from './basic'
import { routes as professionalRoutes } from './professional'


const storage: any = localStorage.getItem('store')
const storageArr = JSON.parse(storage)

let viewMode = 'basic'
if (storageArr) viewMode = storageArr.store.viewMode

let routes = basicRoutes
if ( viewMode === 'professional' ) {
  routes = professionalRoutes
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  if ( to && from ) {
    // do nothing
  }
})

router.beforeEach((to, from) => {
  // ...
  // explicitly return false to cancel the navigation
  // console.log('from: ' , from, 'to: ', to)
  if ( from && to ) {
    // do nothing
  }
})


export default router


import {Vue, Options} from "vue-class-component"
import {signOutUser, switchAccountRequest} from "@/services/AuthService";
import {getAccountsRequest} from "@/services/UserService";
import Swal from "sweetalert2";

const viewModes: any = {
    "basic": {name: "Basic"},
    "professional": {name: "Professionell"}
}

@Options({
	props: {
		accounts: Object
	}
})
export default class Navbar extends Vue {
    viewModes: any = viewModes
	accounts: any
    
    get viewModeName() {
        return viewModes[this.$store.getters.viewMode].name
    }
    
    get viewMode() {
        return this.$store.getters.viewMode
    }
    
	get user() {
		return this.$store.getters.user
	}
	
	changeAccount(user: any) {
		switchAccountRequest(user.id, user.userType).then((res: any) => {
			if ( res.status === 'ERROR' ) {
				return Swal.fire({
					title: 'Fehler',
					text: 'Anmeldung Fehlgeschalgen',
					icon: 'error',
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn bg-danger'
					}
				})
			}
			
			const user = res.result.user
			user.accessToken = res.result.accessToken
			user.refreshToken = res.result.refreshToken
			
			this.$store.dispatch('setUser', user)
			
			if ( this.$route.name == "Dashboard" ) location.reload()
			
			this.$router.push({ name: "Dashboard" })
			// this.getAccounts()
			
			this.$emit('accountChanged')
		})
	}
	
	linkAccount() {
		this.$router.push({name: 'LinkedAccounts'})
	}
	
	logout() {
		signOutUser().then((res: any) => {
			if ( res.result ) {
				this.$store.dispatch('resetUser')
				this.$router.push({name: 'SignIn'})
			}
		})
	}
	
    setNewViewMode(mode: any) {
        this.$store.commit('setViewMode', mode)
        console.log('log', this.$router.currentRoute)
        this.$router.go(0)
    }
	
	getAccounts() {
		getAccountsRequest().then((res: any) => {
			if ( res.status === 'OK' && res.result.length ) {
				this.accounts = res.result
			}
		})
	}
}


export const conf = {
    apiUrl: process.env.NODE_ENV === 'production'?'https://api.nsl.software/api/v2.0':'https://dev-api-nsl-software.kronet.preqtec.net/api/v1.5',
    baseUrl: process.env.NODE_ENV === 'production'?'https://api.nsl.software':'https://dev-api-nsl-software.kronet.preqtec.net',
    xsrf: 'EpQ4CGHDSXZk6UzPVA3W9ABE2BfRpsX5b2qqEZ5wLt9vDKdSeChVRHNDHFge',
    baseFileUrl: process.env.NODE_ENV === 'production' ? 'https://errichter.nsl.software/getFile.php' : 'https://dev-errichter-nsl-software.kronet.preqtec.net/getFile.php',
    socket: {
        apiUrl: "ieyai4Ie.nsl.software:5000"
    }
}


export const _countries = {
    'DE': { name: 'Deutschland', code: 'DE' },
    'CH': { name: 'Switzerland', code: 'CH' },
    'AT': { name: 'Österreich', code: 'AT' },
    'LU': { name: 'Luxemburg', code: 'LU' },
    'AF': { name: 'Afghanistan', code: 'AF' },
    'AL': { name: 'Albania', code: 'AL' },
    'DZ': { name: 'Algeria', code: 'DE' },
    'AS': { name: 'American Samoa', code: 'AS' },
    'AD': { name: 'Andorra', code: 'AD' },
    'AO': { name: 'Angola', code: 'AO' },
    'AI': { name: 'Anguilla', code: 'AI' },
    'AQ': { name: 'Antarctica', code: 'AQ' },
    'AG': { name: 'Antigua And Barbuda', code: 'AG' },
    'AR': { name: 'Argentina', code: 'AR' },
    'AM': { name: 'Armenia', code: 'AM' },
    'AW': { name: 'Aruba', code: 'AW' },
    'AU': { name: 'Australia', code: 'AU' },
    'AZ': { name: 'Azerbaijan', code: 'AZ' },
    'BS': { name: 'Bahamas', code: 'BS' },
    'BH': { name: 'Bahrain', code: 'BH' },
    'BD': { name: 'Bangladesh', code: 'BD' },
    'BB': { name: 'Barbados', code: 'BB' },
    'BY': { name: 'Belarus', code: 'BY' },
    'BE': { name: 'Belgien', code: 'BE' },
    'BZ': { name: 'Belize', code: 'BZ' },
    'BJ': { name: 'Benin', code: 'BJ' },
    'BM': { name: 'Bermuda', code: 'BM' },
    'BT': { name: 'Bhutan', code: 'BT' },
    'BO': { name: 'Bolivia', code: 'BO' },
    'BA': { name: 'Bosnia And Herzegovina', code: 'BA' },
    'BW': { name: 'Botswana', code: 'BW' },
    'BV': { name: 'Bouvet Island', code: 'BV' },
    'BR': { name: 'Brazil', code: 'BR' },
    'IO': { name: 'British Indian Ocean Territory', code: 'IO' },
    'BN': { name: 'Brunei Darussalam', code: 'BN' },
    'BG': { name: 'Bulgarien', code: 'BG' },
    'BF': { name: 'Burkina Faso', code: 'BF' },
    'BI': { name: 'Burundi', code: 'BI' },
    'KH': { name: 'Cambodia', code: 'KH' },
    'CM': { name: 'Cameroon', code: 'CM' },
    'CA': { name: 'Canada', code: 'CA' },
    'CV': { name: 'Cape Verde', code: 'CV' },
    'KY': { name: 'Cayman Islands', code: 'KY' },
    'CF': { name: 'Central African Republic', code: 'CF' },
    'TD': { name: 'Chad', code: 'TD' },
    // 'DE': { name: 'Deutschland', code: 'DE' },
}

/*
    <option value="CL">Chile</option>
    <option value="CN">China</option>
    <option value="CX">Christmas Island</option>
<option value="CC">Cocos  Keeling  Islands</option>
<option value="CO">Colombia</option>
    <option value="KM">Comoros</option>
    <option value="CG">Congo</option>
    <option value="CD">Congo, D.P.R</option>
<option value="CK">Cook Islands</option>
<option value="CR">Costa Rica</option>
<option value="CI">Cote D ivoire</option>
<option value="HR">Kroatien</option>
    <option value="CU">Cuba</option>
    <option value="CY">Zypern</option>
    <option value="CZ">Tschechien</option>
    <option value="DK">Dänemark</option>
    <option value="DJ">Djibouti</option>
    <option value="DM">Dominica</option>
    <option value="DO">Dominican Republic</option>
<option value="TP">East Timor</option>
<option value="EC">Ecuador</option>
    <option value="EG">Egypt</option>
    <option value="SV">El Salvador</option>
<option value="GQ">Equatorial Guinea</option>
<option value="ER">Eritrea</option>
    <option value="EE">Estland</option>
    <option value="ET">Ethiopia</option>
    <option value="FK">Falkland Islands  Malvinas </option>
<option value="FO">Faroe Islands</option>
<option value="FJ">Fiji</option>
    <option value="FI">Finnland</option>
    <option value="FR">Frankreich</option>
    <option value="GF">French Guiana</option>
<option value="PF">French Polynesia</option>
<option value="TF">French Southern Territories</option>
<option value="GA">Gabon</option>
    <option value="GM">Gambia</option>
    <option value="GE">Georgia</option>
    <option value="GH">Ghana</option>
    <option value="GI">Gibraltar</option>
    <option value="GR">Griechenland</option>
    <option value="GL">Greenland</option>
    <option value="GD">Grenada</option>
    <option value="GP">Guadeloupe</option>
    <option value="GU">Guam</option>
    <option value="GT">Guatemala</option>
    <option value="GN">Guinea</option>
    <option value="GW">Guinea-bissau</option>
    <option value="GY">Guyana</option>
    <option value="HT">Haiti</option>
    <option value="HM">Heard And McDonald Islands</option>
<option value="HN">Honduras</option>
    <option value="HK">Hong Kong</option>
<option value="HU">Ungarn</option>
    <option value="IS">Iceland</option>
    <option value="IN">India</option>
    <option value="ID">Indonesia</option>
    <option value="IR">Iran, Islamic Republic Of</option>
<option value="IQ">Iraq</option>
    <option value="IE">Irland</option>
    <option value="IL">Israel</option>
    <option value="IT">Italien</option>
    <option value="JM">Jamaica</option>
    <option value="JP">Japan</option>
    <option value="JO">Jordan</option>
    <option value="KZ">Kazakstan</option>
    <option value="KE">Kenya</option>
    <option value="KI">Kiribati</option>
    <option value="KP">Korea, D.P.R.</option>
<option value="KR">Korea, Republic Of</option>
<option value="KW">Kuwait</option>
    <option value="KG">Kyrgyzstan</option>
    <option value="LA">Lao</option>
    <option value="LV">Lettland</option>
    <option value="LB">Lebanon</option>
    <option value="LS">Lesotho</option>
    <option value="LR">Liberia</option>
    <option value="LY">Libyan Arab Jamahiriya</option>
<option value="LI">Liechtenstein</option>
    <option value="LT">Litauen</option>
    <option value="MO">Macau</option>
    <option value="MK">Macedonia</option>
    <option value="MG">Madagascar</option>
    <option value="MW">Malawi</option>
    <option value="MY">Malaysia</option>
    <option value="MV">Maldives</option>
    <option value="ML">Mali</option>
    <option value="MT">Malta</option>
    <option value="MH">Marshall Islands</option>
<option value="MQ">Martinique</option>
    <option value="MR">Mauritania</option>
    <option value="MU">Mauritius</option>
    <option value="YT">Mayotte</option>
    <option value="MX">Mexico</option>
    <option value="FM">Micronesia</option>
    <option value="MD">Moldova, Republic Of</option>
<option value="MC">Monaco</option>
    <option value="MN">Mongolia</option>
    <option value="MS">Montserrat</option>
    <option value="MA">Morocco</option>
    <option value="MZ">Mozambique</option>
    <option value="MM">Myanmar</option>
    <option value="NA">Namibia</option>
    <option value="NR">Nauru</option>
    <option value="NP">Nepal</option>
    <option value="NL">Niederlande</option>
    <option value="AN">Netherlands Antilles</option>
<option value="NC">New Caledonia</option>
<option value="NZ">New Zealand</option>
<option value="NI">Nicaragua</option>
    <option value="NE">Niger</option>
    <option value="NG">Nigeria</option>
    <option value="NU">Niue</option>
    <option value="NF">Norfolk Island</option>
<option value="MP">Northern Mariana Islands</option>
<option value="NO">Norway</option>
    <option value="OM">Oman</option>
    <option value="PK">Pakistan</option>
    <option value="PW">Palau</option>
    <option value="PS">Palestine</option>
    <option value="PA">Panama</option>
    <option value="PG">Papua New Guinea</option>
<option value="PY">Paraguay</option>
    <option value="PE">Peru</option>
    <option value="PH">Philippines</option>
    <option value="PN">Pitcairn</option>
    <option value="PL">Polen</option>
    <option value="PT">Portugal</option>
    <option value="PR">Puerto Rico</option>
<option value="QA">Qatar</option>
    <option value="RE">Reunion</option>
    <option value="RO">Rumänien</option>
    <option value="RU">Russian Federation</option>
<option value="RW">Rwanda</option>
    <option value="SH">Saint Helena</option>
<option value="KN">Saint Kitts And Nevis</option>
<option value="LC">Saint Lucia</option>
<option value="PM">Saint Pierre And Miquelon</option>
<option value="WS">Samoa</option>
    <option value="SM">San Marino</option>
<option value="ST">Sao Tome And Principe</option>
<option value="SA">Saudi Arabia</option>
<option value="SN">Senegal</option>
    <option value="SC">Seychelles</option>
    <option value="SL">Sierra Leone</option>
<option value="SG">Singapore</option>
    <option value="SK">Slowakai</option>
    <option value="SI">Slowenien</option>
    <option value="SB">Solomon Islands</option>
<option value="SO">Somalia</option>
    <option value="ZA">South Africa</option>
<option value="GS">South Georgia/Sandwich Islands</option>
<option value="ES">Spanien</option>
    <option value="LK">Sri Lanka</option>
<option value="VC">St Vincent/Grenadines</option>
<option value="SD">Sudan</option>
    <option value="SR">Suriname</option>
    <option value="SJ">Svalbard And Jan Mayen</option>
<option value="SZ">Swaziland</option>
    <option value="SE">Schweden</option>
    <option value="SY">Syrian Arab Republic</option>
<option value="TW">Taiwan</option>
    <option value="TJ">Tajikistan</option>
    <option value="TZ">Tanzania, United Republic Of</option>
<option value="TH">Thailand</option>
    <option value="TG">Togo</option>
    <option value="TK">Tokelau</option>
    <option value="TO">Tonga</option>
    <option value="TT">Trinidad And Tobago</option>
<option value="TN">Tunisia</option>
    <option value="TR">Turkey</option>
    <option value="TM">Turkmenistan</option>
    <option value="TC">Turks And Caicos Islands</option>
<option value="TV">Tuvalu</option>
    <option value="UG">Uganda</option>
    <option value="UA">Ukraine</option>
    <option value="AE">United Arab Emirates</option>
<option value="GB">Vereinigtes Königreich</option>
<option value="UY">Uruguay</option>
    <option value="US">United States</option>
<option value="UM">US Minor Outlying Islands</option>
<option value="UZ">Uzbekistan</option>
    <option value="VU">Vanuatu</option>
    <option value="VA">Vatican City</option>
<option value="VE">Venezuela</option>
    <option value="VN">Vietnam</option>
    <option value="VG">Virgin Islands, British</option>
<option value="VI">Virgin Islands, U.S.</option>
<option value="WF">Wallis And Futuna</option>
<option value="EH">Western Sahara</option>
<option value="YE">Yemen</option>
    <option value="YU">Yugoslavia</option>
    <option value="ZM">Zambia</option>
    <option value="ZW">Zimbabwe</option>
 */

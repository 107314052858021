import { createStore } from 'vuex'

export default createStore({
    state: {
        store: {
            user: { userId: null, firstName: '', lastName: '', email: '', userType: null, role: null, accessToken: '', refreshToken: '', rights: null, groups: null, customerType: null },
            viewMode: 'basic',
            viewClass: 'light-mode'
        }
    },
    getters: {
        user (state){
            return state.store.user
        },
        rights (state){
            return state.store.user.rights
        },
        userGroups (state){
            return state.store.user.groups
        },
        viewMode (state) {
            return state.store.viewMode
        },
        viewClass (state) {
            return state.store.viewClass
        }
    },
    mutations: {
        setViewMode(state, data) {
            state.store.viewMode = data
        },
        initialiseStore(state) {
          // Check if the ID exists
            if(localStorage.getItem('store')) {
                // Replace the state object with the stored item
                this.replaceState(
                // @ts-ignore
                    Object.assign(state, JSON.parse(localStorage.getItem('store')))
                );
            }
        },
        setUser(state, data) {
            state.store.user.userId = data.userId
            state.store.user.firstName = data.firstName
            state.store.user.lastName = data.lastName
            state.store.user.email = data.email
            state.store.user.userType = data.userType
            state.store.user.role = data.role
            state.store.user.groups = data.groups
            state.store.user.rights = data.rights
            state.store.user.accessToken = data.accessToken
            state.store.user.refreshToken = data.refreshToken
            state.store.user.customerType = data.customerType
        },
        resetUser(state) {
            state.store.user.userId = null
            state.store.user.firstName = ''
            state.store.user.lastName = ''
            state.store.user.email = ''
            state.store.user.userType = null
            state.store.user.role = null
            state.store.user.groups = null
            state.store.user.rights = null
            state.store.user.accessToken = ''
            state.store.user.refreshToken = ''
            state.store.user.customerType = null
        }
    },
    actions: {
        setUser({commit}, args) {
            commit('setUser', args)
        },
        resetUser({commit}) {
            commit('resetUser')
        }
    },
    modules: {
    }
})

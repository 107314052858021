
import { Options, Vue } from "vue-class-component";
import Swal from "sweetalert2";
import { saveSystemSwitchingExceptionTimes, getSystemSwitchingExceptions, saveAllSystemExceptions, copyExceptionsToSystemsRequest } from "@/services/ObjectService";
declare let $: any;
interface ISwitchingExceptionTime {
        armingTime: string;
        comment: string;
        createdAt: string;
        event: string;
        id: number;
        systemId: string;
        updatedAt: string;
        userId: string;
}

@Options({
    props: {
        systemId: String,
        systems: []
    }
})

export default class SwitchingExceptions extends Vue {
    exceptionStatus: any = 'Unscharf/Scharf';
    unsavedChanges = false;
    switchingExceptionTime = {
        switchingDate: '',
        switchingTime: '',
        kommentar:'',
        typ:'',
        id: 0
    };
    switchingExceptionTimes: ISwitchingExceptionTime[] = [];
    switchingExceptionTimesDefault: ISwitchingExceptionTime[] = [];
    switchingTimesSelected: ISwitchingExceptionTime[] = [];
    systemId: any;
    systems: any;

    mounted(): void {
		this.getSystemSwitchingExceptionsTimes();
        $('#modalEditException').on('hidden.bs.modal', () => {
            this.resetFormFields();
        });

        $('#modalCopySystemExceptionsToSystems').on('hidden.bs.modal', () => {
            this.resetFormFields();
            $("#system_timeRows input[type='checkbox']").prop('checked', false);
        });
	}

    updateExceptionTyp(value: any): void {
        this.exceptionStatus = value;
    }

    validateTime(time: string): boolean {
		const minutes = parseInt(time.split(':')[1])
		let valid = false
		if (!Number.isInteger(minutes / 15)) {
			valid = false
			// muss noch swal rein
			// alert('nicht valide -> swal Meldung')
		} else {
			valid = true
		}
		return valid
	}
    
    getSystemSwitchingExceptionsTimes(): void {
        const data: any = {
            //@ts-ignore
            systemId: this.systemId
        }
        this.switchingExceptionTimes = [];
        getSystemSwitchingExceptions(data).then((response: any)=>{
            if (response && response.status ==='OK') {
                this.switchingExceptionTimes = response.result;
                this.switchingExceptionTimesDefault = Object.assign([], this.switchingExceptionTimes);
            }
        }).catch((error)=>{
            console.log(error);
        });
    }

    saveSystemExceptionTime(): any {
        if (!this.switchingExceptionTime.typ || !this.switchingExceptionTime.switchingDate || !this.switchingExceptionTime.switchingTime) return;
        const data = {
            eventType: this.switchingExceptionTime.typ,
            date: this.switchingExceptionTime.switchingDate + ' ' + this.switchingExceptionTime.switchingTime,
            //@ts-ignore
            systemId: this.systemId,
            comment: this.switchingExceptionTime.kommentar,
            id: this.switchingExceptionTime.id
        };

        const switchingTime = this.switchingExceptionTime.switchingTime


		const startHours = parseInt(switchingTime.split(':')[0])
		const startMinutes = parseInt(switchingTime.split(':')[1])


		let switchingSeconds: any = startHours + (startMinutes / 60)


		let alertMsg = "";
		if (isNaN(switchingSeconds)) {
			alertMsg = 'Sie müssen eine Zeit eingeben !'
		}
		if (!this.validateTime(switchingTime)) {
			alertMsg = 'Minuten können nur in 15 Minuten Schritten gewählt werden !'
		}

        if (alertMsg != "") {
			Swal.fire({
				title: 'Fehler',
				text: alertMsg,
				icon: 'error',
				confirmButtonText: 'OK',
				customClass: {
					confirmButton: 'btn bg-danger'
				},
				buttonsStyling: false
			});
            return;
		}
        
        saveSystemSwitchingExceptionTimes(data).then((res: any) => {
            const message = res?.result?.message ? res?.result?.message : `<p>Ausnahmen wurden erfolgreich gespeichert</p> <br> <p>Der Alarmplan wurde für die ausgewählten Anlagen geändert.</p>`;
			const icon = res?.result?.status == 404 ? 'error' : 'success';
            if (res && res.status === 'OK') {
                Swal.fire({
                    html: message,
                    icon: icon,
                    confirmButtonText: 'OK',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
                $('#modalEditException').modal('hide');
                this.resetFormFields();
                this.getSystemSwitchingExceptionsTimes();
            }
        }).catch((error) => {
            console.log(error);
        });

    }

    showCopyExceptionTimesToModal() {
        // @ts-ignore
		Object.values(this.systems).map((system: any) => {
			if (
                //@ts-ignore
                parseInt(system.anlage_id) !== parseInt(this.systemId) && 
                //@ts-ignore
                (system.anlage_typ_key !== 'anlageNebel' || system.anlage_typ_key !== 'anlageVideo') 
                &&
                system.armingBySystem
                ) {
                    system['enabled'] = true;
                }
		})
		// @ts-ignore
		$('#modalCopySystemExceptionsToSystems').modal('show')
	}

    copyExceptionsToSystems() {
		const systems: any = []
		// @ts-ignore
		const formArr = $('#frm_systemExceptionsCopyToSystems').serializeArray()
		formArr.map((item: any) => {
			systems.push(item.value)
		})
		// @ts-ignore
		$('#modalCopySystemExceptionsToSystems').modal('hide')
		
        const data = {
			systems: systems,
            exceptions: this.switchingTimesSelected
		}

		copyExceptionsToSystemsRequest(data).then((res: any) => {
            const message = res?.result?.message ? res?.result?.message : `<p>Schaltzeiten wurden erfolgreich kopiert.</p> <br> <p>Der Alarmplan wurde für die ausgewählten Anlagen geändert.</p>`;
			const icon = res?.result?.status == 404 ? 'error' : 'success';
			Swal.fire({
				html: message,
				icon: icon,
				confirmButtonText: 'OK',
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn btn-primary'
				}
			});
		})
	}

    deleteAllTimes() {
        this.switchingTimesSelected = [];
        this.switchingTimesSelected = Object.assign([], this.switchingExceptionTimesDefault);
		this.switchingExceptionTimes = [];
        this.switchingExceptionTimesDefault = [];
        this.unsavedChanges = true;
	}

    selectException($event: any): void {
        const isChecked = $event.target.checked;
        const exceptionId = $event.target.value;
        const systemException: any = this.switchingExceptionTimesDefault.find(x=>x.id === exceptionId);
        if (isChecked) {
            this.switchingTimesSelected.push(systemException);
        } else {
            const systemExceptionIndex: any = this.switchingTimesSelected.findIndex(x=>x.id === exceptionId);
            this.switchingTimesSelected.splice(systemExceptionIndex, 1);
        }
        console.log(this.switchingTimesSelected)
    }

    saveAllSystemExceptions(): void {
        Swal.fire({
			title: '',
			html: '<p>Haben Sie die Zeiten für <u><b>alle</b></u> Wochentage korrekt eingetragen? Bitte vermeiden Sie mehrfache Änderungen an einem Tag!</p>',
			showCancelButton: true,
			showConfirmButton: true,
			cancelButtonText: 'Zeiten noch mal korrigieren',
			confirmButtonText: 'Alarmplan jetzt ändern',
			customClass: {
				cancelButton: 'btn btn-accent',
				confirmButton: 'btn btn-primary'
			},
			buttonsStyling: false
		}).then(res => {
			if (res.isConfirmed) {
                saveAllSystemExceptions(this.switchingTimesSelected).then((res: any) => {
                    const message = res?.result?.message ? res?.result?.message : 'Ausnahme wurden gespeichert !';
			        const icon = res?.result?.status == 404 ? 'error' : 'success';
					if (res.status === 'ERROR') return false
					this.unsavedChanges = false
                    this.switchingTimesSelected = [];
					Swal.fire({
						text: message,
                        icon,
						showCancelButton: true,
						showConfirmButton: false,
						cancelButtonText: 'Ok',
						customClass: {
							cancelButton: 'btn btn-primary'
						},
						buttonsStyling: false
					})
				})
            }
        }).catch(error=>{
            console.log(error)
        })
    }

    deleteSwitchingException(switchingExceptionTime: ISwitchingExceptionTime) {
        // Since we use checkboxes for copying only, we empty the array & checkboxed before deleting
        this.switchingTimesSelected = [];
        $("#system_timeRows input[type='checkbox']").prop('checked', false);

		const index = this.switchingExceptionTimes.findIndex(i => i.id === switchingExceptionTime.id);
		if (index >= 0) {
            this.switchingTimesSelected.push(switchingExceptionTime);
			this.switchingExceptionTimes.splice(index, 1);
			this.unsavedChanges = true
		}
	}

    editSwitchingException(id: any) {
        let switchingTime: any = this.switchingExceptionTimes.find(x=>x.id === id)!;
        this.switchingExceptionTime.kommentar  = switchingTime?.comment;
        const dateTime = switchingTime?.armingTime.split(/(\s+)/);
        this.switchingExceptionTime.switchingDate = dateTime[0];
        this.switchingExceptionTime.switchingTime = dateTime[2];
        switch (switchingTime?.event) {
            case '100':
            this.switchingExceptionTime.typ  = 'Unscharf';
            break;
            
            case '200':
            this.switchingExceptionTime.typ  = 'Scharf';
            break;
        }
        this.switchingExceptionTime.id = switchingTime.id;
        $('#modalEditException').modal('show');
    }

    resetFormFields(): void {
        $("#ausnahme-form").trigger('reset');
        $("#frm_systemExceptionsCopyToSystems").trigger('reset');
        this.switchingTimesSelected = [];
        this.switchingExceptionTime = {
            switchingDate: '',
            switchingTime: '',
            kommentar:'',
            typ:'',
            id: 0
        };
    }
}

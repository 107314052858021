import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "font-weight-bold mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterBar = _resolveComponent("FilterBar")!
  const _component_ObjectCard = _resolveComponent("ObjectCard")!
  const _component_ObjectDetailBar = _resolveComponent("ObjectDetailBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.headline), 1),
    _createVNode(_component_FilterBar, {
      filter: _ctx.filter,
      search: _ctx.search,
      onFilter: _ctx.onFilter
    }, null, 8, ["filter", "search", "onFilter"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjects, (item, pIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: pIndex,
        class: "row"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (project, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "col-12 col-md-6 col-lg-6"
          }, [
            _createVNode(_component_ObjectCard, {
              data: project,
              onClicked: _ctx.showProjectDetail
            }, null, 8, ["data", "onClicked"])
          ]))
        }), 128))
      ]))
    }), 128)),
    _createVNode(_component_ObjectDetailBar, {
      show: _ctx.showProjectDetailBar,
      details: _ctx.projectDetail,
      onClose: _ctx.closeProjectDetail
    }, null, 8, ["show", "details", "onClose"])
  ]))
}

import { Vue } from "vue-class-component";
import {checkSignalsRequest} from "@/services/SignalService";

export default class ObjectSystemOverview extends Vue {
    objectId: any
    systemId: any
    system: any = {}
    
    tabs: any = {
		'general': {headline: "Allgemein", text: "Ändern Sie Ihre Bezeichnung für diese Anlage", class: "", icon: "icon-cog", pathName: "SystemData", errors: null},
		'contacts': {headline: "Kontakte", text: "Wer wird bei welchem Ereignis angerufen", class: "", icon: "icon-users", pathName: "ObjectSystemContactInterventionTypes", errors: null},
		'switchingTimes': {headline: "Schaltzeiten", text: "Automatische Scharf- und Unscharfschaltung", class: "", icon: "icon-alarm", pathName: "ObjectSystemSwitchingTimes", errors: null, disabled: false},
		'components': {headline: "Komponenten", text: "Übersicht der verbauten Komponenten", class: "", icon: "icon-hammer-wrench", pathName: "ObjectSystemComponents", errors: null},
		'interventions': {headline: "Interventionen", text: "Wie wird bei einem Alarm-Ereignis gehandelt", class: "", icon: "icon-accessibility", pathName: "ObjectSystemInterventionInterventionTypes", errors: null}
    }
	
	get headline() {
		return 'Anlage: ' + this.$route.meta.headline
	}
	
	get userRights() {
		return this.$store.getters.rights
	}
	
    showDetails(pathName: string) {
		if ( !this.userRights.arming?.write ) return false
        this.$router.push({name: pathName, params: {id: this.objectId, system: this.systemId}})
    }
	
	beforeMount() {
		this.objectId = this.$route.params.id
		this.systemId = this.$route.params.system
		
		checkSignalsRequest('system', this.systemId).then((res: any) => {
			if ( res.result.systems ) {
				const system = res.result.systems.items[this.systemId]
				console.log(system)
				this.tabs.contacts.errors = system.contacts.errors
				this.tabs.interventions.errors = system.interventions.errors
			}
		})
	}
    
    mounted() {
        //
    }
    
}

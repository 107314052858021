
import { getEmployees } from "@/services/EmplyeeService";
import {
	getSystemContactsByIdRequest,
	saveAllSystemContactsRequest
} from "@/services/ObjectService";
import { Vue, Options } from "vue-class-component";
import draggable from 'vuedraggable'
import Swal from "sweetalert2";

@Options({
    components: {draggable},
	watch: {
		systemId() {
			this.onPageLoad()
		}
	}
})
export default class ObjectSystemContacts extends Vue {
    headline = "Kontakte zur Anlage "
    objectId: any
    contacts : any[] = []
    showContactModal = false
	selectedEmployee: any = null
    unsavedChanges= false
	employees: any[] = []
    
    /*
    filterContacts(data: any) {
        data.map((contact: any, index: number) => {
            contact.id = index
            this.contacts.push(contact)
        })
    }
     */
	
	get systemId():any {
		return this.$route.params.system
	}
    
    editSystemContact(contactId: any) {
		this.selectedEmployee = contactId
        // @ts-ignore
        $('#modalEditSystemContact').modal('show')
    }
    
    deleteSystemContact(contactId: any) {
        const index = this.contacts.findIndex(contact => contact.id == contactId)
        this.contacts.splice(index, 1)
        this.unsavedChanges = true
    }
    
    saveSystemContact() {
		const empId = this.selectedEmployee
	    const empIndex = this.employees.findIndex(elem => elem.id === empId )
	    const emp = this.employees[empIndex]
	    
	    if ( this.contacts.findIndex(item => item.id === emp.id) >= 0 ) {
			return Swal.fire({
				title: 'Fehler',
				text: 'Kontakt befindet sich bereits in der Liste',
				icon: 'error',
				timer: 2000,
				customClass: {
					confirmButton: 'btn btn-danger'
				},
				buttonsStyling: false
			})
	    }
	    
	    this.contacts.push(emp)
	    
		// @ts-ignore
	    $('#modalEditSystemContact').modal('hide')
        this.unsavedChanges = true
    }
    
    saveSystemContacts() {
        const sendContacts: any = []
        
	    this.contacts.map((item: any) => {
            sendContacts.push(item.id)
        })
        
        saveAllSystemContactsRequest( this.systemId, sendContacts ).then((res: any) => {
            this.unsavedChanges = false
            console.log(res)
        })
    }
    
    startDrag(event: any) {
        console.log(event)
    }
    
    onDrop(event: any) {
        console.log(event)
        this.unsavedChanges = true
    }
    
    openContactModal() {
        // @ts-ignore
        $('#modalEditSystemContact').modal('show')
    }
    
	onPageLoad() {
		this.contacts = []
		this.employees = []
		
		this.objectId = this.$route.params.id
		
		getEmployees().then((res: any) => {
			if ( res.result ) {
				this.employees = res.result
			}
		})
		
		getSystemContactsByIdRequest(this.systemId).then((res: any) => {
			console.log(res)
			if ( res.result.length > 0 ) {
				console.log('YAIII')
				this.contacts = res.result
			} else {
				console.log('NEYYY')
			}
			// this.filterContacts(res.result.Kontakte)
		})
	}
	
    mounted() {
        this.onPageLoad()
    }
    
}


import { getProjectComponentsRequest } from '@/services/ObjectService'
import { Vue } from 'vue-class-component'
import { conf } from "@/config/conf";
import { getComponentFilesRequest, getComponentsGroupsRequest } from '@/services/ComponentService';
import Swal from "sweetalert2";

export default class ObjectComponents extends Vue {
    headline = "Komponenten"
	components: any[] = []
	componentGroups: any = {}
	config = conf
	
	get user() {
		return this.$store.getters.user
	}
	
	editGroup(groupId: any) {
		this.$router.push( { name: "ObjectComponentGroupDetails", params: { group: groupId } })
	}
	
	getComponentFiles(componentId: any) {
		getComponentFilesRequest(componentId).then((res: any) => {
			console.log(res)
			if (res.result?.length <= 0) {
				Swal.fire({
					title: 'Error',
					text: 'Zu dieser Komponente existieren keine Bilder',
					icon: 'error',
					customClass: {
						confirmButton: 'btn btn-danger'
					},
					buttonsStyling: false
				})
			}
			let images: any[] = []
			res.result.map((img: any) => {
				images.push({
					src  : img.target,
					opts : {
						caption : img.description,
						thumb   : img.target
					}
				})
			})
			// @ts-ignore
			$.fancybox.open(images, {
				loop : false,
				buttons: [
					"zoom",
					//"share",
					"slideShow",
					"fullScreen",
					"download",
					"thumbs",
					"close"
				],
			});
		})
	}
	
	async beforeMount() {
		const objectId = this.$route.params.id
		
		
		await getComponentsGroupsRequest().then((res: any) => {
			if ( res.result ) {
				res.result.map((item: any) => {
					const group = {
						id: item.id,
						name: item.name,
						alias: item.alias,
						class: item.class,
						icon: item.icon,
						items: []
					}
					
					this.componentGroups[parseInt(item.id)] = group
				})
				
				this.componentGroups[0] = {
					name: "Keiner Guppe zugewiesen",
					alias: "Ohne Gruppe",
					class: "dark",
					icon: "fa fa-home",
					items: []
				}
			}
		})
		
		await getProjectComponentsRequest(objectId).then((res: any) => {
			if ( res.status == 'OK' ) {
				res.result.map((item: any) => {
					console.log(item, parseInt(item.componentGroup))
					this.componentGroups[parseInt(item.componentGroup)].items.push(item)
				})
				console.log(res)
				this.components = res.result
			}
		})
	}
}


import { Vue, Options } from "vue-class-component";
import ObjectCard from "@/components/Object/ObjectCard.vue";
import { getProjectsOverviewRequest } from "@/services/ObjectService";
import ObjectDetailBar from "@/components/Object/ObjectDetailBar.vue";
import FilterBar from "@/components/FilterBar.vue";
import {getFilterRequest, hideLoadingLayer, showLoadingLayer} from "@/services/Globals";

@Options({
    components: {FilterBar, ObjectDetailBar, ObjectCard}
})
export default class Objects extends Vue {
    
    headline = 'Objektübersicht'
    projects = []
    projectDetail: any = {}
    showProjectDetailBar = false
	filter: any = {}
	search = ''
	filterArr: any = {}
    
    get filteredProjects() {
        return this.filterProjects(this.projects);
    }
    
    filterProjects(projects: any) {
        let ret: any = {}
        
        if (projects) {
            projects.map((item: any) => {
                let status = parseInt(item.p_status)
                if (status == 1) status = 10
                if (status == 2) status = 20
                if (status == 30) status = 10
                if (!ret[status]) ret[status] = []
	            
                ret[status].push(item)
            })
        }
        return ret
    }
    
    showProjectDetail(project: any) {
        this.projectDetail = project
        this.showProjectDetailBar = true
    }
    
    closeProjectDetail() {
        this.showProjectDetailBar = false
    }
	
	onFilter(args: any) {
		this.search     = args.search
		this.filter     = args.filter
		this.filterArr  = args.filterArray
		
		this.getObjects()
	}
	
	getObjects() {
		const data: any = {
			search: this.search,
			filter: this.filterArr
		}
		
		getProjectsOverviewRequest(data).then((res: any) => {
			if ( res.result ) {
				this.projects = res.result.projekte
			}
			
			hideLoadingLayer()
		})
	}
	
    mounted(){ // when document ready
		showLoadingLayer()
	
	    getFilterRequest('objects').then((res: any) => {
		    if ( !res.result || !res.result.filter ) return false
		
		    this.filter = res.result.filter
		    
		    this.filter.status.items.active.checked = true
		    this.filterArr.status = []
		    this.filterArr.status.push(20)
		
		    this.getObjects()
	    })
	 
		
    }
}


import { getProjectByIdRequest } from "@/services/ObjectService";
import { Vue } from "vue-class-component";
import {checkSignalsRequest} from "@/services/SignalService";
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import {getInterventionTypesRequest} from "@/services/InterventionService";

export default class ObjectSystems extends Vue {
    headline = "Anlagen"
    objectId: any
    systems: any = []
	errors: any = {}
	interventionTypesConf: any = {}
	
    
    filterSystems(args: any) {
        args.systems.map((system: any) => {
	        system.errors = null
	        if ( this.errors?.systems.items[system.anlage_id]?.errors !== null ) {
				system.errors = this.errors?.systems.items[system.anlage_id]?.errors
	        }
            this.systems.push(system)
        })
    }
    
    showSystem(systemId: number | string) {
        this.$router.push({name: "ObjectSystemOverview", params: {id: this.objectId, system: systemId}})
    }
	
	beforeMount() {
		this.objectId = this.$route.params.id
		
		checkSignalsRequest('project', this.objectId).then((res: any) => {
			console.log(res.result)
			this.errors = res.result
		})
		/*
		getInterventionTypesRequest().then((res: any) => {
			this.interventionTypesConf = res.result
		})
		 */
	}
    
    mounted() {
        this.objectId = this.$route.params.id
	
	    showLoadingLayer()
        
        getProjectByIdRequest(this.$route.params.id).then((res: any) =>{
            this.filterSystems(res.result)
	        
	        hideLoadingLayer()
        })
    }
    
}

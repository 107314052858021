
import { Vue } from "vue-class-component"
import {addFirebasePushTokenRequest, signInUser} from "@/services/AuthService";
import Swal from "sweetalert2";

export default class SignIn extends Vue {
	username = ''
	password = ''
	protectPassword = true
	
	forgotPassword(evt: any) {
		evt.preventDefault()
		this.$router.push({name: 'ForgotPassword'})
	}
	
	signIn() {
		signInUser(this.username, this.password).then( (res: any) => {
			if ( res.status === 'ERROR' ) {
				Swal.fire({
					title: 'Fehler',
					text: 'Anmeldung Fehlgeschalgen',
					icon: 'error',
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn bg-danger'
					}
				})
			}
			const user = res.result.user
			user.accessToken = res.result.accessToken
			user.refreshToken = res.result.refreshToken
			
			this.$store.dispatch('setUser', user)
			
			if ( this.$router.currentRoute.value.redirectedFrom ) {
				return window.location.href = this.$router.currentRoute.value.redirectedFrom.fullPath
			}
			
			if ( this.$router.currentRoute.value.query.platform && this.$router.currentRoute.value.query.fpt && this.$router.currentRoute.value.query.device ) {
				const data = {
					platform: this.$router.currentRoute.value.query.platform,
					firebaseToken: this.$router.currentRoute.value.query.fpt,
					deviceId: this.$router.currentRoute.value.query.device
				}
				
				addFirebasePushTokenRequest(data).then(() => {
					
					window.location.href = "/"
				})
			} else {
				window.location.href = "/"
			}
			
		})
	}
	
	checkForEnter(evt: any) {
		if ( evt.keyCode === 13 ) {
			this.signIn()
		}
	}
	
	created() {
		// this.$router.currentRoute.value.query
	}
}

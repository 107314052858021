import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'


export function getComponentFilesRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Component/getComponentFiles',
            method: "POST",
            data: { id: id },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getComponentsGroupsRequest(data = {}): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Component/getGroups',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getComponentGroupByIdRequest(data = {}): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Component/getGroupById',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveComponentGroupRequest(group: any) {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Component/saveGroup',
            method: "POST",
            data: group,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}
import { RouteRecordRaw } from 'vue-router'
import Dashboard from "@/views/Dashboard.vue";
import ObjectOverview from "@/views/basic/Objects/ObjectOverview.vue";
import ObjektKennwort from "@/views/basic/Objects/Objektkennwort.vue";
import ObjectContacts from "@/views/basic/Objects/ObjectContacts.vue";
import ObjectSwitchingTimes from "@/views/basic/Objects/ObjectSwitchingTimes.vue";
import ObjectLocationMaps from "@/views/basic/Objects/ObjectLocationMaps.vue";
import ObjectComponents from "@/views/basic/Objects/ObjectComponents.vue";
import ObjectSystems from "@/views/basic/Objects/ObjectSystems.vue";
import ObjectSystemOverview from "@/views/basic/Objects/ObjectSystemOverview.vue";
import ObjectSystemSwitchingTimes from "@/views/basic/Objects/ObjectSystemSwitchingTimes.vue";
import ObjectSystemContacts from "@/views/basic/Objects/ObjectSystemContacts.vue";
import Objects from "@/views/basic/Objects/Objects.vue";
import ObjectDetail from "@/views/basic/Objects/ObjectDetail.vue";
import SignIn from "@/views/Auth/SignIn.vue";
import store from "@/store/index.ts";

export const shouldAllowAccessToBuchhaltung = (to:any, from:any, next:any): any => {
    const user = store.getters.user;
    if (user.customerType == '1') {
        next(); // allow to enter route
    } else {
        next('/');
    }
}


export const routes: Array<RouteRecordRaw> = [
    {
        path: '/signature-example',
        name: 'SignatureExample',
        component: () => import(/* webpackChunkName: "about" */ '../views/Signature/Example.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/socket-example',
        name: 'SocketExample',
        component: () => import(/* webpackChunkName: "about" */ '../views/SocketExample.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/sign-in',
        name: 'SignIn',
        component: SignIn,
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/sign-out',
        name: 'SignOut',
        component: () => import('../views/Auth/SignOut.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            transitionName: 'fade',
            activeTab: 'Dashboard'
        }
    },
    {
        path: '/user-data',
        name: 'UserData',
        component: () => import(/* webpackChunkName: "about" */ '../views/User/UserData.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Settings',
            breadcrumbs: [
                {name: 'Meine Daten', active: true}
            ]
        }
    },
    /*
    {
        path: '/notification',
        name: 'Notification',
        component: () => import( '../views/Notification.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Settings',
            breadcrumbs: [
                {name: 'Benachrichtigung', active: true}
            ]
        }
    },
    */
    {
        path: '/presence-times',
        name: 'PresenceTimes',
        component: () => import(/* webpackChunkName: "about" */ '../views/Presence/PresenceTimes.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Settings',
            breadcrumbs: [
                {name: 'Meine Daten', active: true}
            ]
        }
    },
    {
        path: '/change-phone-number',
        name: 'ChangePhoneNumber',
        component: () => import(/* webpackChunkName: "about" */ '../views/User/ChangePhoneNumber.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'ChangePhoneNumber',
            breadcrumbs: [
                {name: 'Telefunnummer wechseln', active: true}
            ]
        }
    },
    {
        path: '/link-account',
        name: 'LinkAccount',
        component: () => import(/* webpackChunkName: "about" */ '../views/Account/LinkAccount.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'LinkedAccounts',
            breadcrumbs: [
                {name: 'Accounts verknüpfen', active: true}
            ]
        }
    },
    {
        path: '/linked-accounts',
        name: 'LinkedAccounts',
        component: () => import(/* webpackChunkName: "about" */ '../views/Account/LinkedAccounts.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'LinkedAccounts',
            breadcrumbs: [
                {name: 'Meine Accounts', active: true}
            ]
        }
    },
    {
        path: '/company',
        name: 'Company',
        component: () => import(/* webpackChunkName: "about" */ '../views/Company/Company.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Settings',
            breadcrumbs: [
                {name: 'Firmendaten', active: true}
            ]
        }
    },
    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "about" */ '../views/User/ForgotPassword.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/confirmPasswordReset/:hash',
        name: 'ConfirmPasswordReset',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/ConfirmPasswordReset.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/confirmInvitation/:hash',
        name: 'ConfirmInvitation',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/ConfirmInvitation.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/confirmPhoneValidation/:hash',
        name: 'ConfirmPhoneValidation',
        component: () => import(/* webpackChunkName: "about" */ '../views/User/ConfirmPhoneValidation.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/impersonate/:hash',
        name: 'Impersonate',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Impersonate.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/system-logs',
        name: 'SystemLogs',
        component: () => import(/* webpackChunkName: "about" */ '../views/SystemLog/SystemLogs.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/imported-contacts',
        name: 'ImportedContacts',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contacts/ImportedContacts.vue'),
        meta: {transitionName: 'fade'}
    },


    {
        path: '/contact-config-projects',
        name: 'ContactConfigProjects',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contacts/Projects.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', active: true}
            ]
        }
    },

    {
        path: '/contact-config-projects/:id',
        name: 'InterventionsAllOrIndividual',
        component: () => import('../views/Contacts/InterventionsAllOrIndividual.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'ContactConfigProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Einstellungen', view: 'InterventionsAllOrIndividual'},
                {name: 'Kontakte', active: true},
            ]
        }
    },

    {
        path: '/contact-config-projects/:id/conf',
        name: 'ContactConfigProcess',
        component: () => import('../views/Contacts/ContactConfigProcess.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'ContactConfigProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Einstellungen', view: 'InterventionsAllOrIndividual'},
                {name: 'Kontakte', active: true},
            ]
        }
    },

    {
        path: '/contact-config-projects/:id/conf/:type',
        name: 'ContactConfigProcessIndividual',
        component: () => import('../views/Contacts/ContactConfigProcess.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'ContactConfigProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Einstellungen', view: 'InterventionsAllOrIndividual'},
                {name: 'Kontakte', active: true},
            ]
        }
    },

    {
        path: '/contact-config-projects/:id/individual-systems',
        name: 'InterventionsIndividual',
        component: () => import('../views/Contacts/InterventionsIndividual.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'ContactConfigProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Einstellungen', view: 'InterventionsAllOrIndividual'},
                {name: 'Kontakte', active: true},
            ]
        }
    },

    /*
    {
        path: '/contact-config-projects/:id/interventions-all-or-individual',
        name: 'InterventionsAllOrIndividual',
        component: () => import('../views/Contacts/InterventionsAllOrIndividual.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'ContactConfigProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Einstellungen', view: 'InterventionsAllOrIndividual'},
                {name: 'Kontakte', active: true},
            ]
        }
    },
    {
        path: '/contact-config-projects/:id/:grouping/systems-all-or-individual',
        name: 'SystemsAllOrIndividual',
        component: () => import('../views/Contacts/SystemsAllOrIndividual.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'ContactConfigProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Einstellungen', view: 'InterventionsAllOrIndividual'},
                {name: 'Kontakte', active: true},
            ]
        }
    },
    {
        path: '/contact-config-projects/:id/:grouping/systems-all-or-individual/:type',
        name: 'SystemsAllOrIndividualByIntervention',
        component: () => import('../views/Contacts/SystemsAllOrIndividual.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'ContactConfigProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Einstellungen', view: 'InterventionsIndividual'},
                {name: '', param: 'type', paramType: 'interventionType'},
                {name: 'Kontakte', active: true},
            ]
        }
    },
    {
        path: '/contact-config-projects/:id/:grouping/intervention-individual',
        name: 'InterventionsIndividual',
        component: () => import('../views/Contacts/InterventionsIndividual.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'ContactConfigProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Einstellungen', view: 'InterventionsIndividual'},
                {name: 'Kontakte', active: true},
            ]
        }
    },
    {
        path: '/intervention-config-success',
        name: 'InterventionConfigSuccess',
        component: () => import('../views/Contacts/Success.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'ContactConfigProjects'},
                {name: 'Einstellungen', view: 'InterventionsAllOrIndividual'},
                {name: 'Kontakte', active: true},
                {name: 'Erfolgreich', active: true},
            ]
        }
    },
    */




    {
        path: '/switching-times-projects',
        name: 'SwitchingTimesProjects',
        component: () => import(/* webpackChunkName: "about" */ '../views/SwitchingTime/Projects.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', active: true},
            ]
        }
    },
    {
        path: '/switching-times-projects/:id/systems',
        name: 'SwitchingTimesConfigSystems',
        component: () => import(/* webpackChunkName: "about" */ '../views/SwitchingTime/Systems.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'SwitchingTimesProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Systems', active: true},
            ]
        }
    },
    {
        path: '/switching-times-projects/:id/systems/:system/times',
        name: 'SwitchingTimesConfigTimes',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Objects/ObjectSystemSwitchingTimes.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'SwitchingTimesProjects'},
                {name: '', param: 'id', paramType: 'object'},
                {name: 'Systems', view: 'SwitchingTimesConfigSystems'},
                {name: '', param: 'system', paramType: 'system'},
                {name: 'Schaltzeiten', active: true},
            ]
        }
    },





    {
        path: '/tickets',
        name: 'Tickets',
        component: () => import(/* webpackChunkName: "about" */ '../views/Ticket/Tickets.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Tickets',
            breadcrumbs: [
                {name: 'Tickets', active: true}
            ]
        }
    },

    {
        path: '/new-ticket',
        name: 'NewTicket',
        component: () => import(/* webpackChunkName: "about" */ '../views/Ticket/TicketNew.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Tickets',
            breadcrumbs: [
                {name: 'Tickets', active: true}
            ]
        }
    },
    {
        path: '/tickets/:id',
        name: 'TicketDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/Ticket/TicketDetail.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Tickets',
            breadcrumbs: [
                {name: 'Tickets'},
                {name: 'Tickets', param: "id", paramType: "ticket", active: true}
            ]
        }
    },
    {
        path: '/employees',
        name: 'Employees',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employee/Employees.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Employees',
            breadcrumbs: [
                {name: 'Benutzer', active: true}
            ]
        }
    },
    /* EMPLOYEE GROUPS */
    /*
    {
        path: '/employee-groups',
        name: 'EmployeeGroups',
        component: () => import('../views/EmployeeGroups/EmployeeGroups.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Employees',
            breadcrumbs: [
                {name: 'Benutzer / Gruppen', active: true}
            ]
        }
    },
    {
        path: '/employee-groups/:group',
        name: 'EmployeeGroupDetails',
        component: () => import('../views/EmployeeGroups/EmployeeGroupDetails.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Employees',
            breadcrumbs: [
                {name: 'Benutzer / Gruppen', active: true}
            ]
        }
    },
    {
        path: '/employee-groups/new',
        name: 'EmployeeGroupNew',
        component: () => import('../views/EmployeeGroups/EmployeeGroupDetails.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Employees',
            breadcrumbs: [
                {name: 'Benutzer / Gruppen', view: "EmployeeGroups"},
                {name: 'Neu', active: true}
            ]
        }
    },
    */
    {
        path: '/employees/:id',
        name: 'EmployeeDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employee/EmployeeDetails.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Employees',
            breadcrumbs: [
                {name: 'Benutzer', view: 'Employees'},
                {name: '', param: 'id', paramType: 'employee', active: true}
            ]
        }
    },
    {
        path: '/employees/new',
        name: 'EmployeeNew',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employee/EmployeeDetails.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Employees',
            breadcrumbs: [
                {name: 'Benutzer', view: 'Employees'},
                {name: 'Neuer Benutzer', active: true}
            ]
        }
    },
    {
        path: '/employee/new',
        name: 'NewEmployee',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employee/NewEmpProcess.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Employees',
            breadcrumbs: [
                {name: 'Benutzer', view: 'Employees'},
                {name: 'Neuer Benutzer', active: true}
            ]
        },
        /*
        children: [
            {
                path: ":step",
                name: "NewEmployeeMain",
                component: () => import('../views/Employee/NewEmpMain.vue')
            },
            {
                path: ":step",
                name: "NewEmployeeExtend",
                component: () => import('../views/Employee/NewEmpExtend.vue')
            }
        ]
         */
    },
    {
        path: '/login-data',
        name: 'LoginData',
        component: () => import(/* webpackChunkName: "about" */ '../views/User/LoginData.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Settings',
            breadcrumbs: [
                {name: 'Zugangsdaten', active: true}
            ]
        }
    },
    {
        path: '/switching-times',
        name: 'SwitchingTimes',
        component: () => import(/* webpackChunkName: "about" */ '../views/SwitchingTime/SwitchingTimes.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'SwitchingTimes',
            breadcrumbs: [
                {name: 'Alle Schaltzeiten', active: true}
            ]
        }
    },
    {
        path: '/location-maps',
        name: 'LocationMaps',
        component: () => import(/* webpackChunkName: "about" */ '../views/LocationMap/LocationMaps.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'LocationMaps',
            breadcrumbs: [
                {name: 'Alle Lagepläne', active: true}
            ]
        }
    },
    {
        path: '/contacts-overview',
        name: 'ContactsOverview',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contacts/ContactsOverview.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'ContactsOverview',
            breadcrumbs: [
                {name: 'Alle Kontakte', active: true}
            ]
        }
    },
    {
        path: '/interventions-overview',
        name: 'InterventionsOverview',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/InterventionsOverview.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'ContactsOverview',
            breadcrumbs: [
                {name: 'Alle Interventionen', active: true}
            ]
        }
    },
    {
        path: '/invoice',
        name: 'Invoice',
        beforeEnter: shouldAllowAccessToBuchhaltung,
        component: () => import(/* webpackChunkName: "about" */ '../views/Invoice/Invoice.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Accounting',
            breadcrumbs: [
                {name: 'Rechnungen', active: true}
            ]
        }
    },
    {
        path: '/contracts',
        name: 'Contracts',
        beforeEnter: shouldAllowAccessToBuchhaltung,
        component: () => import(/* webpackChunkName: "about" */ '../views/Contract/Contracts.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Accounting',
            breadcrumbs: [
                {name: 'Verträge', active: true}
            ]
        }
    },
    {
        path: '/not-synced',
        name: 'NotSynced',
        component: () => import(/* webpackChunkName: "about" */ '../views/Sync/NotSynced.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Accounting',
            breadcrumbs: [
                {name: 'Einstellungen übertragen', active: true}
            ]
        }
    },
    {
        path: '/overall-view/:type',
        name: 'OverallView',
        component: () => import(/* webpackChunkName: "about" */ '../views/OverallView/Main.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Overview',
            breadcrumbs: [
                {name: 'Auswertungen', active: true},
                {name: '', param: 'type', paramType: "overview", active: true},
            ]
        }
    },
    {
        path: '/history',
        name: 'History',
        component: () => import(/* webpackChunkName: "about" */ '../views/History/Main.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'History',
            breadcrumbs: [
                {name: 'History', active: true},
            ]
        }
    },
    {
        path: '/history/switching-times',
        name: 'HistorySwitchingTimes',
        component: () => import(/* webpackChunkName: "about" */ '../views/History/SwitchingTimes.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'History',
            breadcrumbs: [
                {name: 'History', active: true},
                {name: 'Schaltzeiten', active: true},
            ]
        }
    },
    {
        path: '/history/exceptions',
        name: 'HistoryExceptions',
        component: () => import(/* webpackChunkName: "about" */ '../views/History/Exceptions.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'History',
            breadcrumbs: [
                {name: 'History', active: true},
                {name: 'Ausnahmen', active: true},
            ]
        }
    },
    {
        path: '/history/interventions',
        name: 'HistoryInterventions',
        component: () => import(/* webpackChunkName: "about" */ '../views/History/Interventions.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'History',
            breadcrumbs: [
                {name: 'History', active: true},
                {name: 'Interventionen', active: true},
            ]
        }
    },
    {
        path: '/history/intervention-contacts',
        name: 'HistoryInterventionContacts',
        component: () => import(/* webpackChunkName: "about" */ '../views/History/InterventionContacts.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'History',
            breadcrumbs: [
                {name: 'History', active: true},
                {name: 'Interventionskontakte', active: true},
            ]
        }
    },
    {
        path: '/history/presence-times',
        name: 'HistoryPresenceTimes',
        component: () => import(/* webpackChunkName: "about" */ '../views/History/PresenceTimes.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'History',
            breadcrumbs: [
                {name: 'History', active: true},
                {name: 'Anwesenheitszeiten', active: true},
            ]
        }
    },
    {
        path: '/history/object-contact-person',
        name: 'HistoryObjectContactPerson',
        component: () => import(/* webpackChunkName: "about" */ '../views/History/ObjectContactPerson.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'History',
            breadcrumbs: [
                {name: 'History', active: true},
                {name: 'Projekt Ansprechpartner.', active: true},
            ]
        }
    },
    {
        path: '/history/employee',
        name: 'HistoryEmployee',
        component: () => import(/* webpackChunkName: "about" */ '../views/History/Employee.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'History',
            breadcrumbs: [
                {name: 'History', active: true},
                {name: 'Nutzer.', active: true},
            ]
        }
    },
    {
        path: '/presence-times-objects',
        name: 'PresenceTimesObjects',
        component: () => import(/* webpackChunkName: "about" */ '../views/Presence/PresenceTimesObjects.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: '', active: true}
            ]
        }
    },
    {
        path: '/alerts',
        name: 'Alerts',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/Alerts.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Alerts',
            breadcrumbs: [
                {name: 'Alarme', active: true}
            ]
        }
    },
    {
        path: '/alert/:id',
        name: 'AlertOverview',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/AlertOverview.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Alerts',
            breadcrumbs: [
                {name: 'Alarme', view: 'Alerts'},
                {name: 'Alarmübersicht', param: 'id', active: true}
            ]
        }
    },
    {
        path: '/alert/:id/preview',
        name: 'AlertPreview',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/AlertPreview.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Alerts',
            breadcrumbs: [
                {name: 'Alarme', view: 'Alerts'},
                {name: 'Alarmübersicht', param: 'id', active: true}
            ]
        }
    },
    {
        path: '/alert/:id/details',
        name: 'AlertDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/AlertDetailPoVi.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Alerts',
            breadcrumbs: [
                {name: 'Alarme', view: 'Alerts'},
                {name: 'Alarmübersicht', param: 'id', paramType: 'alert', view: 'AlertOverview'},
                {name: 'Alarm Details', active: true}
            ]
        }
    },
    {
        path: '/alert/:id/reason',
        name: 'AlertReason',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/AlertReason.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Alerts',
            breadcrumbs: [
                {name: 'Alarme', view: 'Alerts'},
                {name: 'Alarmübersicht', param: 'id', paramType: 'alert', view: 'AlertOverview'},
                {name: 'Auslösegrund / Videos', active: true}
            ]
        }
    },
    {
        path: '/alert/:id/route-map',
        name: 'AlertRouteMap',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/AlertRouteMap.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Alerts',
            breadcrumbs: [
                {name: 'Alarme', view: 'Alerts'},
                {name: 'Alarmübersicht', param: 'id', paramType: 'alert', view: 'AlertOverview'},
                {name: 'Anfahrtsweg', active: true}
            ]
        }
    },
    {
        path: '/alert/:id/location-maps',
        name: 'AlertLocationMaps',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/AlertLocationMaps.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Alerts',
            breadcrumbs: [
                {name: 'Alarme', view: 'Alerts'},
                {name: 'Alarmübersicht', param: 'id', paramType: 'alert', view: 'AlertOverview'},
                {name: 'Lagepläne', active: true}
            ]
        }
    },
    {
        path: '/objects',
        name: 'Objects',
        component: Objects,
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', active: true}
            ]
        }
    },
    {
        path: '/object/:id',
        name: 'ObjectOverview',
        component: ObjectOverview,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/details',
        name: 'ObjectDetails',
        component: ObjectDetail,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Objektdetails', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/contact-person',
        name: 'ObjectContactPerson',
        component: () => import('../views/basic/Objects/ObjectContactPerson.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Objektdetails', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/object-kennwort',
        name: 'Objektkennwort',
        component: () => import('../views/basic/Objects/Objektkennwort.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                { name: 'Objekte', view: 'Objects' },
                { name: '', param: 'id', paramType: 'object', view: 'ObjectOverview' },
                { name: 'Objektdetails', active: true }
            ]
        },
        props: true
    },
    {
        path: '/object/:id/contact-person-intervention',
        name: 'ObjectContactPersonIntervention',
        component: () => import('../views/basic/Objects/ObjectContactPersonIntervention.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Objektdetails', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/notification',
        name: 'Notification',
        component: () => import(/* webpackChunkName: "about" */ '../views/Notification.vue'),
        meta: {
            transitionName: 'fade',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Benachrichtigung', active: true}
            ]
        }
    },
    {
        path: '/object/:id/alerts',
        name: 'ObjectAlerts',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/Alerts.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Alarme', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/presence-times',
        name: 'ObjectPresenceTimes',
        component: () => import(/* webpackChunkName: "about" */ '../views/Presence/PresenceTimes.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anwesenheit', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/employees',
        name: 'ObjectEmployees',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employee/Employees.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Benutzer', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/invoices',
        name: 'ObjectInvoices',
        beforeEnter: shouldAllowAccessToBuchhaltung,
        component: () => import(/* webpackChunkName: "about" */ '../views/Invoice/Invoice.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Rechnungen', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/contracts',
        name: 'ObjectContracts',
        beforeEnter: shouldAllowAccessToBuchhaltung,
        component: () => import(/* webpackChunkName: "about" */ '../views/Contract/Contracts.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Verträge', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/files',
        name: 'ObjectFiles',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Objects/ObjectFiles.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Unterlagen', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/contacts',
        name: 'ObjectContacts',
        component: ObjectContacts,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Kontakte', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/switching-times',
        name: 'ObjectSwitchingTimes',
        component: ObjectSwitchingTimes,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Schaltzeiten', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/location-maps',
        name: 'ObjectLocationMaps',
        component: ObjectLocationMaps,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Lagepläne', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/components',
        name: 'ObjectComponents',
        component: ObjectComponents,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Komponenten', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/component-groups',
        name: 'ObjectComponentGroups',
        component: () => import(/* webpackChunkName: "about" */ '../views/ComponentGroup/ComponentGroups.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Komponentengruppen', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/component-groups/:group',
        name: 'ObjectComponentGroupDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/ComponentGroup/ComponentGroupDetails.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Komponentengruppen', view: 'ObjectComponentGroups'},
                {name: 'Details', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/systems',
        name: 'ObjectSystems',
        component: ObjectSystems,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system',
        name: 'ObjectSystemOverview',
        component: ObjectSystemOverview,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/switching-times',
        name: 'ObjectSystemSwitchingTimes',
        component: ObjectSystemSwitchingTimes,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Schaltzeiten', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/system-data',
        name: 'SystemData',
        component: () => import('../views/basic/Objects/ObjectSystemData.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Allgemein', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/contacts',
        name: 'ObjectSystemContacts',
        component: ObjectSystemContacts,
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Ereignisse', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/components',
        name: 'ObjectSystemComponents',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Objects/ObjectSystemComponents.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Komponenten', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/contact-intervention-types',
        name: 'ObjectSystemContactInterventionTypes',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/ContactsByInterventionType.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Ereignisse', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/contact-intervention-types/:type',
        name: 'SystemInterventionTypeContacts',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/ContactsByInterventionType.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Interventionstypen', view: 'ObjectSystemContactInterventionTypes'},
                {name: '', param: 'type', paramType: 'interventionType', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/contact-intervention-types/:type/take-over',
        name: 'ContactTakeOver',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/ContactTakeOver.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Interventionstypen', view: 'ObjectSystemContactInterventionTypes'},
                {name: '', param: 'type', paramType: 'interventionType', view: 'SystemInterventionTypeContacts'},
                {name: 'Übernehmen', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/intervention-intervention-types',
        name: 'ObjectSystemInterventionInterventionTypes',
        // component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/InterventionInterventionTypes.vue'),
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/InterventionsByInterventionType.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Ereignisse', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/intervention-intervention-types/:type',
        name: 'SystemInterventionTypeInterventions',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/InterventionsByInterventionType.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Interventionstypen', view: 'ObjectSystemInterventionInterventionTypes'},
                {name: '', param: 'type', paramType: 'interventionType', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/intervention-intervention-types/:type/:intervention',
        name: 'ObjectSystemInterventionDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/EditIntervention.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Interventionstypen', view: 'ObjectSystemInterventionInterventionTypes'},
                {name: '', param: 'type', paramType: 'interventionType', view: 'SystemInterventionTypeInterventions'},
                {name: 'Details', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/intervention-intervention-types/:type/new',
        name: 'ObjectSystemInterventionNew',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/EditIntervention.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'system', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Interventionstypen', view: 'ObjectSystemInterventionInterventionTypes'},
                {name: '', param: 'type', paramType: 'interventionType', view: 'SystemInterventionTypeInterventions'},
                {name: 'Neu', active: true}
            ]
        },
        props: true
    },
    {
        path: '/object/:id/system/:system/intervention-intervention-types/:type/take-over',
        name: 'InterventionTakeOver',
        component: () => import(/* webpackChunkName: "about" */ '../views/basic/Intervention/InterventionTakeOver.vue'),
        meta: {
            transitionName: 'slide',
            activeTab: 'Objects',
            breadcrumbs: [
                {name: 'Objekte', view: 'Objects'},
                {name: '', param: 'id', paramType: 'object', view: 'ObjectOverview'},
                {name: 'Anlagen', view: 'ObjectSystems'},
                {name: '', param: 'id', paramType: 'system', view: 'ObjectSystemOverview'},
                {name: 'Interventionstypen', view: 'ObjectSystemInterventionInterventionTypes'},
                {name: '', param: 'id', paramType: 'interventionType', view: 'SystemInterventionTypeInterventions'},
                {name: 'Übernahme', active: true}
            ]
        },
        props: true
    },
    {
        path: "/forbidden",
        name: 'Forbidden',
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    },
    {
        path: "/:catchAll(.*)",
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    }
]



import { Vue, Options } from "vue-class-component"
import {_weekdays} from "@/services/Globals";
import Swal from "sweetalert2";

@Options({
	props: {
		times: Object,
		editable: Boolean
	}
})
export default class CircuitDiagram extends Vue {
	weekdays: any = _weekdays
	times: any
	editable: any
	
	get filteredTimes(): any {
		return this.filterTimes(this.times)
	}
	
	addTime(weekdayIndex: number) {
		this.$emit('addTime', { weekdayIndex })
	}
	
	showAsImage() {
		// const svg: any = document.getElementById('test-svg');
		const svg: any = document.querySelector('svg')
		
		const svgBounds = svg.getBoundingClientRect()
		
		const clone = svg.cloneNode(true)
		
		const  outerHTML = clone.outerHTML
		const blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});
		
		
		let URL = window.URL || window.webkitURL || window;
		let blobURL = URL.createObjectURL(blob);
		
		let image = new Image();
		image.onload = () => {
			
			let canvas = document.createElement('canvas');
			
			canvas.width = svgBounds.width;
			
			canvas.height = svgBounds.height;
			
			let context: any = canvas.getContext('2d');
			// draw image in canvas starting left-0 , top - 0
			context.drawImage(image, 0, 0, svgBounds.width, svgBounds.height );
			//  downloadImage(canvas); need to implement
			
			
			let jpeg = canvas.toDataURL('image/jpg');
			console.log(jpeg)
		};
		image.src = blobURL;
		
		window.open(blobURL)
		
		console.log(image)
		
		return true
		
		
		const xml = new XMLSerializer().serializeToString(svg);
		const svg64 = btoa(xml); //for utf8: btoa(unescape(encodeURIComponent(xml)))
		const b64start = 'data:image/svg+xml;base64,';
		const image64 = b64start + svg64;
		
		const img = document.createElement('img')
		img.src = image64
		
		document.body.appendChild(img)
	}
	
	filterTimes(data: any) {
		let timesByWeekday: any = []
		
		Object.keys(this.weekdays).map((wd: any) => {
			timesByWeekday[wd] = { intervals: [], strokes: [], rects: [], circles: [], onPins: [], offPins: [] }
		})
		
		if ( data.interval && data.interval.length > 0 ) {
			
			data.interval.map((interval: any) => {
				timesByWeekday[interval.validateTime.weekdayNumber].intervals.push(interval)
			})
			
			timesByWeekday.map((wd: any, index: any) => {
				const loops = wd.intervals.length
				let circles: any = []
				let strokes: any = []
				let stroke = ''
				let rects: any = []
				let startX = 40
				let actualX = startX
				
				
				if ( wd.intervals.length > 0 ) {
					const sortedIntervals = wd.intervals.sort((a: any, b: any) => {
						return a.time[0] - b.time[0]
					})
					
					for (let i = 0; i < loops; i++) {
						sortedIntervals.map((interval: any) => {
							const timeStart = parseFloat(interval.time[0]) - index * 24
							const timeEnd = parseFloat(interval.time[1]) - index * 24
							const xStart = timeStart * 10 + 40
							const xEnd = timeEnd * 10 + 40
							
							
							
							
							if ( xStart >= actualX ) {
								let stroke1 = `M ${ actualX },10 L ${xStart},10 `
								
								if ( parseFloat(sortedIntervals[0].time[0]) - index * 24 === 0 ) stroke1 = `M ${ actualX },10 L ${xStart},20 `
								
								console.log('stroke q', stroke1, interval.time)
								
								strokes.push({ stroke: stroke1 })
								stroke += ` L ${ xStart },10`
								
								let strokePlaceholder = { x: actualX, y: 10, width: xStart-actualX, height: 10 }
								rects.push(strokePlaceholder)
								
								let strokeRect = `M ${ xStart },10 L ${xStart},20 L ${xEnd},20 L ${xEnd},10 `
								strokes.push({ stroke: strokeRect, interval })
								
								stroke += ` L ${xStart},20 L ${xEnd},20 L ${xEnd},10 `
								
								circles.push({cx: xStart, cy: 20})
								circles.push({cx: xStart, cy: 10})
								circles.push({cx: xEnd, cy: 10})
								circles.push({cx: xEnd, cy: 20})
								
								
								actualX = xEnd
							} else {
								// stroke += `L ${ timeStart }, 10`
							}
						})
					}
					
					if ( actualX < 280 ) {
						strokes.push({ stroke: `M ${ actualX },10 L 280,10` })
						rects.push({ x: actualX, y: 10, width: 280-actualX, height: 10 })
						
						stroke += ` L ${ actualX },10 L 280,10 `
					}
					
					
					if ( parseFloat(sortedIntervals[0].time[0]) - index * 24 === 0 ) stroke = `M 40,20` + stroke
					else stroke =`M 40,10` + stroke
					
					
				} else {
					strokes.push({ stroke: `M ${ actualX },10 L 280,10` })
					rects.push({ x: actualX, y: 10, width: 280-actualX, height: 10 })
					stroke = `M 40,10 L 280,10`
				}
				timesByWeekday[index].strokes = strokes
				timesByWeekday[index].circles = circles
				timesByWeekday[index].rects = rects
				timesByWeekday[index].stroke = stroke
			})
		}
		
		if ( data.on ) {
			data.on.map((on: any) => {
				const time = ( parseFloat(on.on) - parseInt(on.validateTime.weekdayNumber) * 24 ) * 10 + 40
				on.x = time
				timesByWeekday[on.validateTime.weekdayNumber].onPins.push(on)
			})
		}
		
		if ( data.off ) {
			data.off.map((off: any) => {
				const time = ( parseFloat(off.off) - parseInt(off.validateTime.weekdayNumber) * 24 ) * 10 + 40
				off.x = time
				timesByWeekday[off.validateTime.weekdayNumber].offPins.push(off)
			})
		}
		
		console.log(timesByWeekday)
		
		return timesByWeekday
	}
	
	showIntervalPreview(stroke: any, evt: any) {
		const hoveredElement = evt.target
		const elemOffset = hoveredElement.getBoundingClientRect()
		const posX = elemOffset.x
		const posY = elemOffset.y
		
		if ( !stroke.interval ) return false
		
		const titleTag = document.createElement('span')
		titleTag.innerHTML = `
				<i class="fal fa-clock-o"></i>
				<span class="ml-2">
					${stroke.interval.validateTime.start.hours}:${stroke.interval.validateTime.start.minutes}
						-
					${stroke.interval.validateTime.end.hours}:${stroke.interval.validateTime.end.minutes}
				</span>
			`
		titleTag.id = 'titleTag'
		titleTag.className = 'title-tag'
		titleTag.style.top = (posY - 50) + 'px'
		titleTag.style.left = posX +'px'
		
		const removeElem: any = document.querySelector('#titleTag')
		
		if (removeElem) {
			removeElem.parentNode.removeChild(removeElem)
		}
		
		document.body.append(titleTag)
	}
	
	removeIntervalPreview() {
		const removeElem: any = document.querySelector('#titleTag')
		
		if (removeElem) {
			removeElem.parentNode.removeChild(removeElem)
		}
	}
	
	showOnTime(on: any) {
		if ( !this.editable ) return false
		console.log(on)
		Swal.fire({
			title: 'Unscharf',
			text: `${ on.validateTime.start.hours }:${ on.validateTime.start.minutes }`,
			showConfirmButton: true,
			showCancelButton: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn btn-success',
				cancelButton: 'btn btn-warning'
			},
			cancelButtonText: 'Bearbeiten'
		}).then((res: any) => {
			if ( res.isDismissed && res.dismiss === 'cancel' ) {
				this.$emit('onTime', { time: on.on, type: 'on' })
				// this.editSwitchingTime()
			}
		})
	}
	
	showOffTime(off: any) {
		if ( !this.editable ) return false
		console.log(off)
		Swal.fire({
			title: 'Unscharf',
			text: `${ off.validateTime.start.hours }:${ off.validateTime.start.minutes }`,
			showConfirmButton: true,
			showCancelButton: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn btn-success',
				cancelButton: 'btn btn-warning'
			},
			cancelButtonText: 'Bearbeiten'
		}).then((res: any) => {
			if ( res.isDismissed && res.dismiss === 'cancel' ) {
				this.$emit('offTime', { time: off.off, type: 'off' })
				// this.editSwitchingTime()
			}
		})
	}
	
	showInterval(interval: any) {
		if ( !this.editable ) return false
		
		Swal.fire({
			title: 'Interval',
			text: `Von ${ interval.validateTime.start.hours }:${ interval.validateTime.start.minutes } Bis ${ interval.validateTime.end.hours }:${ interval.validateTime.end.minutes }`,
			showConfirmButton: true,
			showCancelButton: true,
			showDenyButton: true,
			confirmButtonText: "Schließen",
			denyButtonText: "Löschen",
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn',
				cancelButton: 'btn btn-accent',
				denyButton: 'btn btn-danger'
			},
			cancelButtonText: 'Bearbeiten'
		}).then((res: any) => {
			if ( res.isDenied ) {
				
				return this.$emit('deleted', {type: 'interval', time: interval})
			}
			
			if ( res.isDismissed && res.dismiss === 'cancel' ) {
				this.$emit('interval', { time: interval.time, type: 'interval' })
				// this.editSwitchingTime()
			}
		})
	}
}

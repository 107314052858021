
// Sidebar.stories.js

import {Options, Vue} from "vue-class-component";
import {signOutUser, switchAccountRequest} from "../services/AuthService";
import Swal from "sweetalert2";

@Options({
	props: {
		accounts: Object
	}
})
export default class Sidebar extends Vue {
	activeRoute: any = ''
	accounts: any
	
    get sidebarMenu () {
	    return [
		    {name: "Dashboard", icon: "icon-grid3", href: "/", routeName: 'Dashboard', visibility: true},
		    {name: "Benutzer ", icon: "icon-users", href: "/employees", routeName: 'Employees', disabled: !this.user.rights?.user?.read, visibility: true },
		    /*
		    {name: "Benutzer ", icon: "icon-users", href: "/employees", routeName: 'Employees', disabled: !this.user.rights.user?.read, subs: [
				    {name: "Benutzer", icon: "icon-user", href: "/employees", routeName: 'Employees'},
				    // {name: "Gruppen", icon: "icon-users", href: "/employee-groups", routeName: 'EmployeeGroups'}
			    ]
		    },
		     */
		    {name: "Objekte", icon: "icon-store", href: "/objects", routeName: 'Objects', visibility: true, subs: [
				    {name: "Objektübersicht", icon: "icon-store", href: "/objects", routeName: 'Objects'},
				    {name: "Anwesenheitszeiten", icon: "icon-watch2", href: "/presence-times-objects", routeName: 'PresenceTimesObjects'},
				    {name: "Schaltzeiten", icon: "icon-alarm", href: "/switching-times", routeName: 'SwitchingTimes'},
				    {name: "Kontakte", icon: "icon-users", href: "/contacts-overview", routeName: 'ContactsOverview'},
				    {name: "Interventionen", icon: "icon-accessibility", href: "/interventions-overview", routeName: 'InterventionsOverview'},
				    {name: "Lagepläne", icon: "icon-map5", href: "/location-maps", routeName: 'LocationMaps'}
			    ]
		    },
		    // {name: "Tickets", icon: "icon-list", href: "/tickets", routeName: 'Tickets', disabled: false},
		    // { name: "Nachrichten", icon: "icon-bubbles4", href: "/" },
		    {name: "Alarme", icon: "icon-warning2", href: "/alerts", routeName: 'Alerts', visibility: true},
		    {name: "Alarmpläne", icon: "icon-accessibility", href: "/overall-view/interventions", routeName: '', disabled: false, visibility: true},
		    /*
		    {
			    name: "Auswertungen", icon: "icon-stats-bars4", routeName: 'Overview', subs: [
				    {name: "Interventionspläne", icon: "icon-accessibility", href: "/overall-view/interventions", routeName: '', disabled: false},
				    {name: "Schaltzeiten", icon: "icon-alarm", href: "/overall-view/switching-times", routeName: '', disabled: false},
				    {name: "Lagepläne", icon: "icon-map5", href: "/overall-view/location-maps", routeName: '', disabled: false},
				    {name: "Kontakte", icon: "icon-users", href: "/overall-view/contacts", routeName: '', disabled: false},
				    {name: "Alarme", icon: "icon-warning", href: "/overall-view/alerts", routeName: 'Alerts', disabled: false},
				    {name: "Rechte", icon: "icon-map5", href: "/overall-view/rights", routeName: 'LocationMaps', disabled: true}
			    ]
		    },
			*/
		    {
			    name: "History", icon: "icon-reset", routeName: 'history', visibility: true, subs: [
				    {name: "Schaltzeiten", icon: "icon-alarm", href: "/history/switching-times", routeName: '', disabled: false},
				    {name: "Ausnahmen", icon: "icon-magic-wand", href: "/history/exceptions", routeName: '', disabled: false},
				    {name: "Interventionen", icon: "icon-accessibility", href: "/history/interventions", routeName: '', disabled: false},
				    {name: "Interventionskontakte", icon: "icon-users", href: "/history/intervention-contacts", routeName: '', disabled: false},
				    {name: "Anwesenheitszeiten", icon: "icon-watch2", href: "/history/presence-times", routeName: '', disabled: false},
				    {name: "Projekt Ansprechp.", icon: "icon-user-tie", href: "/history/object-contact-person", routeName: '', disabled: false},
					{name: "Nutzer bearbeitet", icon: "icon-user", href: "/history/employee", routeName: '', disabled: !(this.user?.role == '10' || this.user?.role == '20')},
			    ]
		    },
		    {
			    name: "Buchhaltung", icon: "icon-file-presentation2", href: "/", routeName: 'Accounting', visibility: this.user.customerType == '1' ? true : false, subs: [
				    {
					    name: "Rechnung",
					    icon: "icon-price-tags2",
					    href: "/invoice",
					    routeName: 'Invoice',
					    disabled: !this.user.rights?.invoice?.read
				    },
				    {
					    name: "Verträge",
					    icon: "icon-file-pdf",
					    href: "/contracts",
					    routeName: 'Contracts',
					    disabled: !this.user.rights?.contracts?.read
				    }
			    ]
		    },
		    {name: "Einstellungen", icon: "icon-cog", routeName: 'Settings', visibility: true, subs: [
				    {name: "Firmendaten", icon: "icon-office", href: "/company", routeName: 'Company', disabled: !this.user.rights?.company?.read},
				    {name: "Meine Daten", icon: "icon-user", href: "/user-data", routeName: 'UserData'},
				    // {name: "Benachrichtigung", icon: "icon-bell3", href: "/notification", routeName: 'Notification'},
				    // {name: "Anwesenheit", icon: "icon-clock", href: "/presence-times", routeName: 'PresenceTimes'},
				    // {name: "Zugangsdaten", icon: "icon-user-lock", href: "/login-data", routeName: 'LoginData'},
				    // {name: "Interventions-Kontakt", icon: "icon-mobile", href: "/change-phone-number", routeName: 'ChangePhoneNUmber', disabled: false},
				    {name: "Accounts verwalten", icon: "icon-users2", href: "/linked-accounts", routeName: 'LinkedAccounts'},
				    // {name: "Sockets", icon: "icon-bubble", href: "/socket-example", routeName: 'SocketExample', disabled: true}
			    ]
		    }
	    ]
    }
	
	get user() {
		return this.$store.getters.user
	}
	
	changeAccount(user: any) {
		switchAccountRequest(user.id, user.userType).then((res: any) => {
			if ( res.status === 'ERROR' ) {
				return Swal.fire({
					title: 'Fehler',
					text: 'Anmeldung Fehlgeschalgen',
					icon: 'error',
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn bg-danger'
					}
				})
			}
			
			const user = res.result.user
			user.accessToken = res.result.accessToken
			user.refreshToken = res.result.refreshToken
			
			this.$store.dispatch('setUser', user)
			
			if ( this.$route.name == "Dashboard" ) location.reload()
			
			this.$router.push({ name: "Dashboard" })
			// this.getAccounts()
			
			this.$emit('accountChanged')
		})
	}
	
	logout() {
		signOutUser().then((res: any) => {
			if ( res.result ) {
				this.$store.dispatch('resetUser')
				
				this.$router.push({name: 'SignOut'})
			}
		})
	}
	
    navigateTo(nav: any) {
        if ( !nav.subs && !nav.disabled ) {
            this.$router.push({path: nav.href})
	        document.body.classList.remove('sidebar-mobile-main')
        }
    }
	
	mounted() {
		this.$router.afterEach((to) => {
			this.activeRoute = to.meta.activeTab
		})
	}
}


import {Options, Vue} from "vue-class-component";
import {checkSignalsRequest} from "@/services/SignalService";
import {
	armingFailureRequest,
	getProjectByIdRequest,
	getSystemLogsRequest,
	setSystemArmingRequest,
	sendEmailToCustomerOnArming
} from "@/services/ObjectService";
import Swal from "sweetalert2";
import {Countdown} from "@/services/Countdown";
import CounterCircle from "@/components/CounterCircle.vue";

interface Tab {
	headline: string
	text: string
	class: string
	icon: string
	pathName: string
	pathParams?: []
	errors?: null | []
	items?: any []
	disabled?: boolean
	visibility?: boolean
}

interface TabList {
	[index: string]: Tab
}

declare let $: any

@Options({
	components: { CounterCircle }
})
export default class ObjectOverview extends Vue {
    objectId: any
	objectStreet = ""
	objectHouseNumber = ""
	objectZip = ""
	objectCity = ""
    tabs: TabList = {
        // 'overview': {headline: "Übersicht", text: "Objektdetails", class: "border-dark text-dark", icon: "icon-grid3", pathName: "ObjectDetails", errors: null},
        'notification': {headline: "Benachrichtigung", text: "Stellen Sie ein welche Benachrichtigungen Sie für dieses Objekt erhalten wollen ", class: "border-dark text-dark", icon: "icon-grid3", pathName: "Notification", errors: null, visibility: true},
        'presence': {headline: "Anwesenheit", text: "Öffnungszeiten / Kommunikationszeiten / Aufenthaltszeiten", class: "border-dark text-dark", icon: "icon-watch2", pathName: "ObjectPresenceTimes", errors: null, visibility: true},
		'objektkennwort': {headline: "Objektkennwort", text: "", class: "border-success text-success", icon: "icon-key", pathName: "Objektkennwort", errors: null, disabled: false, visibility: true},
	    'contactPerson': {headline: "Ansprechpartner", text: "", class: "border-success text-success", icon: "icon-grid3", pathName: "ObjectContactPerson", errors: null, disabled: false, visibility: true},
	    'contactObject': {headline: "Kontakt im Objekt", text: "für Interventionen", class: "border-success text-success", icon: "icon-user", pathName: "ObjectContactPersonIntervention", errors: null, disabled: false, visibility: true},
		'systems': {headline: "Anlagen", text: "Verwalten Sie Ihre Anlagen-Schaltzeiten / Anlagen-Kontakte ", class: "border-success text-success", icon: "icon-home", pathName: "ObjectSystems", errors: null, items: [], visibility: true},
	    // 'employees': {headline: "Benutzer", text: "Verwalten Sie Ihre Benutzer ", class: "border-info text-info", icon: "icon-user", pathName: "ObjectEmployees", errors: null},
        'locationMaps': {headline: "Lagepläne", text: "", class: "border-success text-success", icon: "icon-map4", pathName: "ObjectLocationMaps", errors: null, visibility: true},
        'files': {headline: "Unterlagen", text: "", class: "border-success text-success", icon: "icon-folder-open", pathName: "ObjectFiles", errors: null, visibility: true},
	    // 'tickets': {headline: "Tickets", text: "", class: "border-success text-success", icon: "icon-envelope", pathName: ""},
        // 'rights': {headline: "Rechte", text: "", class: "border-danger text-danger", icon: "icon-envelope", pathName: ""},
        'components': {headline: "Komponenten", text: "", class: "border-primary text-primary", icon: "icon-hammer-wrench", pathName: "ObjectComponents", errors: null, visibility: true},
        // 'componentGroups': {headline: "Komponentengruppen", text: "", class: "border-primary text-primary", icon: "icon-hammer-wrench", pathName: "ObjectComponentGroups", errors: null},
	    'alerts': {headline: "Alarme", text: "", class: "border-danger text-danger", icon: "fal fa-siren", pathName: "ObjectAlerts", errors: null, visibility: true},
        // 'analysis': {headline: "Auswertung", text: "", class: "border-slate text-slate", icon: "icon-envelope", pathName: ""},
        'invoices': {headline: "Rechnung", text: "", class: "border-slate text-slate", icon: "icon-price-tags2", pathName: "ObjectInvoices", errors: null, visibility: true},
        'contracts': {headline: "Verträge", text: "", class: "border-slate text-slate", icon: "icon-file-pdf", pathName: "ObjectContracts", errors: null, visibility: true},
    }
	armingText = ""
	armingSystem = ""
	tryArmingCounter = 0
	armingTime = null
	armingTimeStart = null
	armingRequestInterval: any = null
	
	
	get headline() {
		// console.log(this.$route.meta)
		return this.$route.meta.headline
	}
	
	get user() {
		return this.$store.getters.user
	}
    
    showDetails(pathName: string, pathParams: any = null) {
		const params: any = {
			id: this.objectId
		}
		if ( pathParams ) {
			pathParams.map((item: any) => {
				params[item.key] = item.value
			})
		}
		
        this.$router.push({name: pathName, params: params})
    }
	
	async setArming(system:any, arming: boolean, id: any) {
		const number = system.anlage_nummer
		this.armingSystem = system.anlage_typ+" "+system.anlage_bemerkung
		let maxTries = 2
		this.tryArmingCounter = 0
		
		await new Promise((resolve, reject) => {
			this.tryArming(number, arming, id, system.anlage_bemerkung, maxTries, resolve, reject)
		}).then(() => {
			// console.log('test läuft')
		}).catch((err: any) => {
			console.log(err);
			$('#modal_arming').modal('hide')
			
			Swal.fire({
				icon: 'error',
				title: 'Anlage konnte nicht geschalten werden',
				html: "<p>Die Zentrale wurde bereits über das Probelm informiert und ein Servicearbeiter ist bereits auf dem Weg zu Ihnen um das Problem zu beheben</p>",
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn btn-danger'
				}
			})
			
			armingFailureRequest({systemId: id, arming: arming}).then(() => {
				console.log('juhuhu')
			})
		})
		
	}
	
	tryArming(number: string, arming: boolean, id: any, anlage_bemerkung: any, loop: number, resolve: any, reject: any) {
		this.tryArmingCounter++
		
		this.armingText = arming ? "Scharf" : "Unscharf"
		
		if ( loop <= 0 ) {
			return reject()
		}
		loop = loop - 1
		
		new Promise((resol: any, rejec) => {
			setSystemArmingRequest(number, arming).then((res: any) => {
				const delay = res.result.verzoegerungZeit
				const messageSent = res.result.armingMessageSent
				const errorMessage = res.result.error_message
				
				this.armingTime = delay
				this.armingTimeStart = delay
				
				const countdown = new Countdown()
				countdown.setStart(delay)
				
				if (res.result.type === 'ajax' && messageSent) {
					Swal.fire({
						icon: 'success',
						title: this.armingText + ' erfolgreich durchgeführt',
						buttonsStyling: false,
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					const armingEventNumber = arming ? 200 : 100;
					this.sendEmailToUser(number, id, anlage_bemerkung, armingEventNumber);
					this.getProjectById()
					$('#modal_arming').modal('hide');
					resol();
					return;
				}

				if (!messageSent) {
					clearInterval(this.armingRequestInterval)
					reject(errorMessage); // if message not sent by vodaFone or o2, no need to check again and again
					return;
				}
				
				this.armingRequestInterval = setInterval(() => {
					getSystemLogsRequest({ filter: { system: [id], event: [100, 200]}, limit: 1 }).then((res: any) => {
						if ( res.result?.length > 0 ) {
							
							const armingEventNumber = arming ? 200 : 100
							if ( parseInt(res.result[0].eventId) == armingEventNumber ) {
								
								//write logic to send email to user for arming disarming
								this.sendEmailToUser(number, id, anlage_bemerkung, parseInt(res.result[0].eventId));
								// refresh page
								this.getProjectById()
								
								Swal.fire({
									icon: 'success',
									title: res.result[0].event+' erfolgreich durchgeführt',
									buttonsStyling: false,
									customClass: {
										confirmButton: 'btn btn-primary'
									}
								})
								
								countdown.stop()
								countdown.reset()
								clearInterval(this.armingRequestInterval)
								
								$('#modal_arming').modal('hide')
								
								resol()
							} else {
								console.log('noch nicht umgeschaltet')
							}
						}
					})
					
				}, 10000)
				
				countdown.counting((time: any) => {
					// console.log('ich bin counting')
					this.armingTime = time
				})
				
				countdown.onFinish(() => {
					console.log('ich bin fertig', this.armingRequestInterval)
					clearInterval(this.armingRequestInterval)
					rejec()
				})
				
				countdown.start()
				// this.countdown.start()
				
				$('#modal_arming').modal('show')
			})
		}).then(() => {
			resolve()
		}).catch(() => {
			this.tryArming(number, arming, id, anlage_bemerkung, loop, resolve, reject)
		})
		
	}

	sendEmailToUser(anlageNummer: any, anlageId: any, anlage_bemerkung: any, arming: number): void {
		const data = {
			anlageNummer,
			anlageId,
			anlage_bemerkung,
			arming,
		}
		sendEmailToCustomerOnArming(data).then((res: any) => {
			console.log(res)
			console.log("Email sent successfully")
		}).catch((error) => {
			console.log(error)
		})
	}
	
	getProjectById() {
		this.tabs.invoices.visibility  = this.user.customerType == '1' ? true : false;
		this.tabs.contracts.visibility = this.user.customerType == '1' ? true : false;
		getProjectByIdRequest(this.objectId).then((res: any) => {
			if (res.status !== 'OK') return false
			
			this.objectStreet = res.result.details.p_strasse
			this.objectHouseNumber = res.result.details.p_hnr
			this.objectZip = res.result.details.p_plz
			this.objectCity = res.result.details.p_ort
			
			this.tabs.systems.items = []
			
			const systems = res.result.systems || []
			systems.map((item: any) => {
				const headline: string = item.anlage_bemerkung || ""
				const text: string = item.anlage_typ || ""
				const icon: string = item.icon || ""
				
				this.tabs.systems?.items?.push({
					headline: headline,
					text: text,
					icon: icon,
					pathName: "ObjectSystemOverview",
					pathParams: [
						{ key: 'system', value: item.anlage_id }
					],
					args: {
						switchManually: item.switchManually,
						armingBySystem: item.armingBySystem,
						eventState: item.eventState,
						anlage_id: item.anlage_id,
						anlage_nummer: item.anlage_nummer,
						anlage_typ: item.anlage_typ,
						anlage_bemerkung: item.anlage_bemerkung,
						isTypeHikvision: item.isTypeHikvision
					}
				})
			})
		})
	}
    
	beforeMount() {
		this.objectId = this.$route.params.id
		
		checkSignalsRequest('project', this.objectId).then((res: any) => {
			if ( res.result ) {
				if ( res.result.systems.errors !== null ) this.tabs.systems.errors = res.result.systems.errors
			}
		})
		
		this.getProjectById()
		
	}
	
    
}


import {Options, Vue} from "vue-class-component";
import {_weekdays} from "@/services/Globals";
import Swal from "sweetalert2";

declare let $: any

@Options({
	props: {
		times: Object,
		editable: Boolean
	}
})
export default class Timetable extends Vue {
	weekdays: any = _weekdays
	times: any
	editable: any
	
	get filteredTimes(): any {
		return this.filterTimes(this.times)
	}
	
	addTime(weekdayIndex: number) {
		this.$emit('addTime', { weekdayIndex })
	}
	
	filterTimes(data: any) {
		let timesByWeekday: any = []
		
		Object.keys(this.weekdays).map((wd: any) => {
			timesByWeekday[wd] = { onPins: [], offPins: [] }
		})
		
		
		if ( data.on ) {
			data.on.map((on: any) => {
				const time = ( parseFloat(on.on) - parseInt(on.validateTime.weekdayNumber) * 24 ) * 10 + 40
				on.x = time
				timesByWeekday[on.validateTime.weekdayNumber].onPins.push(on)
			})
		}
		
		if ( data.off ) {
			
			data.off.map((off: any) => {
				
				const time = ( parseFloat(off.off) - parseInt(off.validateTime.weekdayNumber) * 24 ) * 10 + 40
				off.x = time
				timesByWeekday[off.validateTime.weekdayNumber].offPins.push(off)
			})
		}
		
		return timesByWeekday
	}
	
	showOnTime(on: any) {
		if ( !this.editable ) return false
		console.log(on)
		Swal.fire({
			title: 'Unscharf',
			text: `${ on.validateTime.start.hours }:${ on.validateTime.start.minutes }`,
			showConfirmButton: true,
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: "Schließen",
			denyButtonText: "Löschen",
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn',
				cancelButton: 'btn btn-accent',
				denyButton: 'btn btn-danger'
			},
			cancelButtonText: 'Bearbeiten'
		}).then((res: any) => {
			if ( res.isDenied ) {
				
				return this.$emit('deleted', {type: 'on', time: on})
			}
			
			if ( res.isDismissed && res.dismiss === 'cancel' ) {
				this.$emit('onTime', { time: on.on, type: 'on' })
				// this.editSwitchingTime()
			}
		})
	}
	
	showOffTime(off: any) {
		if ( !this.editable ) return false
		console.log(off)
		Swal.fire({
			title: 'Scharf',
			text: `${ off.validateTime.start.hours }:${ off.validateTime.start.minutes }`,
			showConfirmButton: true,
			showCancelButton: true,
			showDenyButton: true,
			confirmButtonText: "Schließen",
			denyButtonText: "Löschen",
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn',
				cancelButton: 'btn btn-accent',
				denyButton: 'btn btn-danger'
			},
			cancelButtonText: 'Bearbeiten'
		}).then((res: any) => {
			if ( res.isDenied ) {
				
				return this.$emit('deleted', {type: 'off', time: off})
			}
			
			if ( res.isDismissed && res.dismiss === 'cancel' ) {
				this.$emit('offTime', { time: off.off, type: 'off' })
				// this.editSwitchingTime()
			}
		})
	}
}

import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'

export function checkSignalsRequest(checkType: any, id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Signal/checkForSignals',
            method: "POST",
            data: { checkType: checkType, id: id },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

import {Options, Vue} from "vue-class-component"

@Options({
	props: {
		filter: {
			type: Object
		},
		search: {
			type: String,
			default: ""
		},
		sort: {
			type: Object,
			default: null
		}
	},
	watch: {
		search(val) {
			this.frm.search = val
		},
		filter(val) {
			this.frm.filter = val
		}
	}
})
export default class FilterBar extends Vue {
	filter: any
	search: any
	sort: any | null
	frm: any = {
		filter: {},
		search: ""
	}
	showDropdown = false

	uncheck(item: any) {
		item.checked = !item.checked
		this.change()
	}
	
	change() {
		const filterKeys = Object.keys(this.frm.filter)
		let filterArr: any = {}
		
		filterKeys.map((key: any) => {
			filterArr[key] = []
			const itemArr = Object.values(this.frm.filter[key].items)
			itemArr.map((subItem: any) => {
				if ( subItem.checked ) filterArr[key].push(subItem.value)
			})
		})
		
		this.$emit('filter', { filter: this.frm.filter, search: this.frm.search, filterArray: filterArr })
	}
	
	toggleDropdown(evt: any) {
		evt.stopPropagation()
		evt.preventDefault()
		this.showDropdown = !this.showDropdown
	}
	
	mounted() {
		this.frm.filter = this.filter
		this.frm.search = this.search
		
		
		var dropdownBox: any = document.querySelector('.dropdown-box')
		
		document.addEventListener('click', (event) => {
			var isClickInsideElement = dropdownBox.contains(event.target);
			if (!isClickInsideElement && this.showDropdown) {
				this.showDropdown = !this.showDropdown
			}
		});
		
	}
}


import { Options, Vue } from "vue-class-component";
import { copyTimesToSystemsRequest, getProjectByIdRequest, getSwitchingTimesRequest, saveSystemTimesRequest } from "@/services/ObjectService";
import { _weekdays, _weekdaysShort, validateWeekday } from '@/services/Globals'
import Swal from "sweetalert2";
import CircuitDiagram from "@/components/CircuitDiagram.vue";
import SwitchingExceptions from "@/components/Object/SwitchingExceptions.vue";
import Timetable from "@/components/Timetable.vue";

declare let $: any

@Options({
	components: { Timetable, CircuitDiagram, SwitchingExceptions },
	watch: {
		systemId() {
			this.onPageLoad()
		}
	}
})
export default class ObjectSystemSwitchingTimes extends Vue {
	headline = "Schaltzeiten zur Anlage "
	objectId: any
	weekdays: any = _weekdays
	weekdaysShort: any = _weekdaysShort
	switchingTimes: any = { on: [], off: [], interval: [] }
	copyTime: any = { type: '' }
	copies: any[] = []
	unsavedChanges = false
	system: any = {}
	systems: any = {}
	frmNewSystemTime: any = {
		type: '',
		weekday: 0,
		on: '00:00',
		off: '00:00',
		weekdays: { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false }
	}
	editSystemTime: any = null
	conflicts: any = []
	conflictTime = ''
	times: any = []
	containerWidth = 600
	chartWidth = this.containerWidth - 120

	get systemId(): any {
		return this.$route.params.system
	}

	addTime(args: any) {
		this.frmNewSystemTime.type = ''
		this.frmNewSystemTime.weekday = args.weekdayIndex
		this.frmNewSystemTime.on = '00:00'
		this.frmNewSystemTime.off = '00:00'
		$('#modalEditSystemTime').modal('show')
	}

	deletedTime(args: any) {
		let index = this.switchingTimes[args.type].indexOf(args.time)
		this.switchingTimes[args.type].splice(index, 1)
		this.unsavedChanges = true
	}

	changeInterval(data: any) {
		this.editSwitchingTime(data.time, data.type)
	}

	changeOnTime(data: any) {
		this.editSwitchingTime(data.time, data.type)
	}

	changeOffTime(data: any) {
		this.editSwitchingTime(data.time, data.type)
	}

	showCopyTimesToSystemsModal() {
		const systemsArr = Object.values(this.systems)
		let systems: any = []
		systemsArr.map((system: any) => {
			if (parseInt(system.anlage_id) !== parseInt(this.systemId) && system.anlage_typ_key !== 'anlageNebel' && system.anlage_typ_key !== 'anlageVideo' && system.anlage_typ_key === this.systems[this.systemId].anlage_typ_key) systems.push(system)
		})
		console.log('systems', this.systems)
		// $('#frm_systemTimeCopyToSystems').html(createCopySystemRows(systems))
		// @ts-ignore
		$('#modalCopySystemTimesToSystems').modal('show')
	}

	copyTimesToSystems() {
		const systems: any = []
		// @ts-ignore
		const formArr = $('#frm_systemTimeCopyToSystems').serializeArray()
		formArr.map((item: any) => {
			systems.push(item.value)
		})
		// @ts-ignore
		$('#modalCopySystemTimesToSystems').modal('hide')

		let validateInterval: any = []
		let validateOnTimes: any = []
		let validateOffTimes: any = []

		this.switchingTimes.interval.map((item: any) => {
			let validateTime: any = []
			validateTime.push(parseFloat(item.time[0]).toFixed(2))
			validateTime.push(parseFloat(item.time[1]).toFixed(2))
			validateInterval.push(validateTime)
		})

		this.switchingTimes.on.map((item: any) => {
			console.log('ons', item)
			validateOnTimes.push(parseFloat(item.on).toFixed(2))
		})

		this.switchingTimes.off.map((item: any) => {
			console.log('offs', item)
			validateOffTimes.push(parseFloat(item.off).toFixed(2))
		})

		const data = {
			times: validateInterval,
			turnOn: validateOnTimes,
			turnOff: validateOffTimes,
			systems: systems
		}
		//console.log(systems)

		copyTimesToSystemsRequest(data).then((res: any) => {
			const message = res?.result?.message ? res?.result?.message : 'Schaltzeiten wurden erfolgreich kopiert.</p> <br> <p>Der Alarmplan wurde für die ausgewählten Anlagen geändert.';
			const icon = res?.result?.status == 404 ? 'error' : 'success';
			console.log(res)
			Swal.fire({
				html: message,
				icon: icon,
				confirmButtonText: 'OK',
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn btn-primary'
				}
			})
		})
	}

	deleteAllTimes() {
		this.switchingTimes.interval = []
		this.switchingTimes.on = []
		this.switchingTimes.off = []

		this.unsavedChanges = true
	}

	saveAndOverwriteTimes() {
		this.conflicts.map((item: any) => {
			const index = this.switchingTimes.interval.indexOf(item)
			console.log(index)
			this.switchingTimes.interval.splice(index, 1)
		})

		this.saveSystemTime()
		// @ts-ignore
		$('#modalSystemTimeConflict').modal('hide')
	}

	copySwitchingTime(weekday: string, from: string | null, to: string | null, type: string) {
		this.copyTime.type = type

		// const weekdayIndex  = _weekdays.indexOf(weekday)
		let start: any = null
		if (from !== null) {
			const startTime = from
			const startHours = parseInt(startTime.split(':')[0])
			const startMinutes = parseInt(startTime.split(':')[1])
			start = startHours + (startMinutes / 60)
		} else {
			// start = null
		}
		let end: any = null
		if (to !== null) {
			const endTime = to
			const endHours = parseInt(endTime.split(':')[0])
			const endMinutes = parseInt(endTime.split(':')[1])
			end = endHours + (endMinutes / 60)
		} else {
			// end = null
		}

		// search for collissions
		const keys = Object.keys(_weekdays)
		this.copies = []
		keys.map((index: any) => {
			let conflict: any = null
			if (type === 'interval') {
				this.switchingTimes.interval.find((elem: any) => {
					if (parseFloat(elem.time[0]) < start + (index * 24) && parseFloat(elem.time[1]) > start + (index * 24)) conflict = { time: elem.time }
					else if (parseFloat(elem.time[0]) < end + (index * 24) && parseFloat(elem.time[1]) > end + (index * 24)) conflict = { time: elem.time }
					else if (parseFloat(elem.time[0]) > start + (index * 24) && parseFloat(elem.time[1]) < end + (index * 24)) conflict = { time: elem.time }
					else if (parseFloat(elem.time[0]) === start + (index * 24) && parseFloat(elem.time[1]) === end + (index * 24)) conflict = { time: elem.time }
				})
			} else if (type === 'on') {
				start = 0
			} else if (type === 'off') {
				end = 0
			}

			const disabled = conflict !== null ? true : false

			// console.log(disabled, conflict)


			if (conflict === null) conflict = {}

			conflict.disabled = disabled
			conflict.weekday = _weekdays[parseInt(index)]
			conflict.start = start + (index * 24)
			conflict.end = end + (index * 24)
			conflict.timeValue = conflict.start + ',' + conflict.end

			console.log(start, end, type, conflict.timeValue)

			this.copies.push(conflict)
		})
		setTimeout(() => {
			// @ts-ignore
			const elems = Array.prototype.slice.call(document.querySelectorAll('.form-check-input-switchery'))
			elems.forEach(function (html) {
				// @ts-ignore
				new Switchery(html)
			})
			// @ts-ignore
			$('#modalSystemTimeCopy').modal('show')
		}, 100)

	}

	saveCopiedTimes() {
		// @ts-ignore
		const formArr: any = $('#frm_systemTimeCopy').serializeArray()
		console.log(formArr)
		formArr.map((item: any) => {
			if (this.copyTime.type === 'interval') {
				const time = item.value.split(',')
				const durationHours = Math.floor((parseInt(time[1]) - parseInt(time[0])))
				const durationMinutes = ((parseInt(time[1]) - parseInt(time[0])) * 60) % 60
				const validateTime = validateWeekday(time[0], time[1])
				const weekday = validateTime.weekday
				this.switchingTimes.interval.push({ durationHours, durationMinutes, validateTime, weekday, time })
			} else if (this.copyTime.type === 'on') {
				const on = parseInt(item.value.split(',')[0])
				const validateTime = validateWeekday(on, 0)
				const weekday = validateTime.weekday

				this.switchingTimes.on.push({ validateTime, weekday, on })
			} else if (this.copyTime.type === 'off') {
				const off = parseFloat(item.value.split(',')[0])
				const validateTime = validateWeekday(off, 0)
				const weekday = validateTime.weekday

				this.switchingTimes.off.push({ validateTime, weekday, off })
			}
		})

		// swal !!

		this.unsavedChanges = true
	}

	editSwitchingTime(time: any, type: any) {

		console.log('ausgabe: ', time, type)

		let validateTime: any = {}

		console.log('time', validateTime)

		switch (type) {
			case 'on':
				validateTime = validateWeekday(time, 0)
				this.frmNewSystemTime.off = validateTime.start.hours + ':' + validateTime.start.minutes
				this.frmNewSystemTime.type = 'turnOff'
				break
			case 'off':
				validateTime = validateWeekday(time, 0)
				this.frmNewSystemTime.on = validateTime.start.hours + ':' + validateTime.start.minutes
				this.frmNewSystemTime.type = 'turnOn'
				break
			case 'interval':
				validateTime = validateWeekday(time[0], time[1])
				this.frmNewSystemTime.off = validateTime.start.hours + ':' + validateTime.start.minutes
				this.frmNewSystemTime.on = validateTime.end.hours + ':' + validateTime.end.minutes
				this.frmNewSystemTime.type = 'interval'
		}

		// reset weekdays
		const keys = Object.keys(this.frmNewSystemTime.weekdays)
		keys.map((index: any) => {
			this.frmNewSystemTime.weekdays[index] = false
		})

		this.frmNewSystemTime.weekdays[validateTime.weekdayNumber] = true

		// @ts-ignore
		$('#modalEditSystemTime').modal('show')
	}

	deleteTime(item: any, type: any) {
		const index = this.switchingTimes[type].indexOf(item)
		if (index >= 0) {
			this.switchingTimes[type].splice(index, 1)
			this.unsavedChanges = true
		}
	}

	saveSystemTime() {
		const type = this.frmNewSystemTime.type
		// const weekdayIndex  = this.frmNewSystemTime.weekday
		const startTime = this.frmNewSystemTime.off
		let endTime = this.frmNewSystemTime.on
		if (endTime === '00:00') endTime = '24:00'


		const startHours = parseInt(startTime.split(':')[0])
		const startMinutes = parseInt(startTime.split(':')[1])

		const endHours = parseInt(endTime.split(':')[0])
		const endMinutes = parseInt(endTime.split(':')[1])

		let start: any = startHours + (startMinutes / 60)
		let end: any = endHours + (endMinutes / 60)


		let alertMsg = "";
		if (isNaN(start) && (type === 'interval' || type === 'turnOff')) {
			alertMsg = 'Sie müssen eine Zeit eingeben !'
		}
		if (isNaN(end) && (type === 'interval' || type === 'turnOn')) {
			alertMsg = 'Sie müssen eine Zeit eingeben !'
		}
		if (!this.validateTime(startTime) && (type === 'interval' || type === 'turnOff')) {
			alertMsg = 'Minuten können nur in 15 Minuten Schritten gewählt werden !'
		}
		if (end < start && type === 'interval') {
			alertMsg = 'Die Endzeit muss nach der Startzeit liegen !'
		}
		if (!this.validateTime(endTime) && (type === 'interval' || type === 'turnOn')) {
			alertMsg = 'Minuten können nur in 15 Minuten Schritten gewählt werden !'
		}

		if (alertMsg != "") {
			return Swal.fire({
				title: 'Fehler',
				text: alertMsg,
				icon: 'error',
				confirmButtonText: 'OK',
				customClass: {
					confirmButton: 'btn bg-danger'
				},
				buttonsStyling: false
			})
		}

		// check caused collisions | only for intervals
		if (type === 'interval') {
			let find: any = []
			let keys = Object.keys(_weekdays)

			keys.map((index: any) => {
				if (this.frmNewSystemTime.weekdays[index]) {
					this.switchingTimes.interval.find((item: any) => {
						const time = item.time

						// const validateTime = validateWeekday(time[0], time[1])
						// const timeItem = {time, validateTime}

						time[0] = parseFloat(time[0])
						time[1] = parseFloat(time[1])


						if (time[0] > start + (index * 24) && time[1] < end + (index * 24)) find.push(item)
						else if (time[0] < end + (index * 24) && time[1] > end + (index * 24)) find.push(item)
						else if (time[0] < start + (index * 24) && time[1] > start + (index * 24)) find.push(item)
						else if (time[0] > start + (index * 24) && time[1] === end + (index * 24)) find.push(item)
						else if (time[0] === start + (index * 24) && time[1] < end + (index * 24)) find.push(item)
						else if (time[0] === start + (index * 24) && time[1] === end + (index * 24)) find.push(item)
					})
				}
			})

			if (find.length > 0) {
				this.conflicts = find
				const newTime = validateWeekday(start, end)
				// @ts-ignore
				$('#modalEditSystemTime').modal('hide')
				// @ts-ignore
				$('#modalSystemTimeConflict').modal('show')
				this.conflictTime = `${newTime.start.hours}:${newTime.start.minutes} - ${newTime.end.hours}:${newTime.end.minutes}`
			} else {
				let keys = Object.keys(_weekdays)

				keys.map((index: any) => {
					if (this.frmNewSystemTime.weekdays[index]) {
						const calcStart = start + (index * 24)
						const calcEnd = end + (index * 24)

						const time = [calcStart, calcEnd]
						const validateTime = validateWeekday(calcStart, calcEnd)
						this.switchingTimes.interval.push({ validateTime, time })
					}
				})


				// @ts-ignore
				$('#modalEditSystemTime').modal('hide')

				/* create chart
					$('#chart').html(createDayChart(_systemTimes, _onTimes, _offTimes))
				*/
				Swal.fire({
					title: 'Erfolgreich',
					text: 'Schaltzeiten wurden aktualisiert.  Bitte speichern Sie Ihre Änderungen',
					icon: 'success',
					showCancelButton: true,
					showConfirmButton: false,
					cancelButtonText: 'Ok',
					customClass: {
						cancelButton: 'btn btn-primary'
					},
					buttonsStyling: false
				})
				this.unsavedChanges = true
			}
		} else if (type === 'turnOn') { // Anlage scharf -> unscharf Schaltung aus / aufgehoben
			let keys = Object.keys(_weekdays)

			keys.map((wd: any) => {
				if (this.frmNewSystemTime.weekdays[wd]) {
					const calcEnd = end + (wd * 24)
					const endAsDouble = calcEnd.toFixed(2).toString()
					const validateTime = validateWeekday(calcEnd, 0)
					const index = this.switchingTimes.off.findIndex((item: any) => item.off === endAsDouble)

					if (index >= 0) { // exist
						this.switchingTimes.off[index] = { validateTime, off: endAsDouble }
					} else { // not exist
						this.switchingTimes.off.push({ validateTime, off: endAsDouble })
					}
				}
			})



			// @ts-ignore
			$('#modalEditSystemTime').modal('hide')

			Swal.fire({
				title: 'Erfolgreich',
				text: 'Schaltzeiten wurden aktualisiert. Bitte speichern Sie Ihre Änderungen',
				showCancelButton: true,
				showConfirmButton: false,
				cancelButtonText: 'Ok',
				customClass: {
					cancelButton: 'btn btn-primary'
				},
				buttonsStyling: false,
				timer: 2000
			})
			this.unsavedChanges = true
		} else if (type === 'turnOff') {// Anlage unscharf -> unscharf Schaltung ein
			let keys = Object.keys(_weekdays)

			keys.map((wd: any) => {
				if (this.frmNewSystemTime.weekdays[wd]) {
					const calcStart = start + (wd * 24)

					const startAsDouble = calcStart.toFixed(2).toString()
					const validateTime = validateWeekday(calcStart, 0)
					const index = this.switchingTimes.on.findIndex((item: any) => item.on === startAsDouble)
					if (index >= 0) { // exist
						this.switchingTimes.on[index] = { validateTime, on: startAsDouble }
					} else { // not exist
						this.switchingTimes.on.push({ validateTime, on: startAsDouble })
					}
				}
			})


			// @ts-ignore
			$('#modalEditSystemTime').modal('hide')

			Swal.fire({
				title: 'Erfolgreich',
				text: 'Schaltzeiten wurden aktualisiert. Bitte speichern Sie Ihre Änderungen',
				showCancelButton: true,
				showConfirmButton: false,
				cancelButtonText: 'Ok',
				customClass: {
					cancelButton: 'btn btn-primary'
				},
				buttonsStyling: false
			})
			this.unsavedChanges = true
		}
	}

	saveAllSystemTimes() {
		Swal.fire({
			title: '',
			html: '<p>Haben Sie die Zeiten für <u><b>alle</b></u> Wochentage korrekt eingetragen? Bitte vermeiden Sie mehrfache Änderungen an einem Tag!</p>',
			showCancelButton: true,
			showConfirmButton: true,
			cancelButtonText: 'Zeiten noch mal korrigieren',
			confirmButtonText: 'Alarmplan jetzt ändern',
			customClass: {
				cancelButton: 'btn btn-accent',
				confirmButton: 'btn btn-primary'
			},
			buttonsStyling: false
		}).then(res => {
			if (res.isConfirmed) {
				let validateInterval: any = []
				let validateOnTimes: any = []
				let validateOffTimes: any = []

				this.switchingTimes.interval.map((item: any) => {
					let validateTime: any = []
					validateTime.push(parseFloat(item.time[0]).toFixed(2))
					validateTime.push(parseFloat(item.time[1]).toFixed(2))
					validateInterval.push(validateTime)
				})

				this.switchingTimes.on.map((item: any) => {
					console.log('ons', item)
					validateOnTimes.push(parseFloat(item.on).toFixed(2))
				})

				this.switchingTimes.off.map((item: any) => {
					console.log('offs', item)
					validateOffTimes.push(parseFloat(item.off).toFixed(2))
				})

				saveSystemTimesRequest(this.systemId, validateInterval, validateOnTimes, validateOffTimes).then((res: any) => {
					const message = res?.result?.message ? res?.result?.message : 'Schaltzeiten wurden gespeichert !';
					const icon = res?.result?.status == 404 ? 'error' : 'success';
					if (res.status === 'ERROR') return false
					this.unsavedChanges = false
					Swal.fire({
						icon,
						text: message,
						showCancelButton: true,
						showConfirmButton: false,
						cancelButtonText: 'Ok',
						customClass: {
							cancelButton: 'btn btn-primary'
						},
						buttonsStyling: false
					})
				})
			} else {
				// do nothing
			}
		})
	}


	filterSwitchingTimes(data: any) {
		console.log(data)

		if (data.intervalle && data.intervalle.length > 0) {

			data.intervalle.map((time: any) => {
				const validateTime = validateWeekday(time[0], time[1])
				this.switchingTimes.interval.push({ validateTime, time })
			})
		}

		if (data.turnOn) {
			data.turnOn.map((on: any) => {
				const validateTime: any = validateWeekday(on, 0)
				this.switchingTimes.on.push({ validateTime, on })
			})
		}

		if (data.turnOff) {
			data.turnOff.map((off: any) => {
				const validateTime: any = validateWeekday(off, 0)
				this.switchingTimes.off.push({ validateTime, off })
			})
		}
	}

	validateTimeOnChange(evt: any) {
		const time = evt.target.value
		this.validateTime(time)
	}

	validateTime(time: string) {
		const minutes = parseInt(time.split(':')[1])
		let valid = false
		if (!Number.isInteger(minutes / 15)) {
			valid = false
			// muss noch swal rein
			// alert('nicht valide -> swal Meldung')
		} else {
			valid = true
		}
		return valid
	}

	filterSystems(args: any) {
		args.systems.map((system: any) => {
			const systemType = system.anlage_typ_key
			if (
				parseInt(system.anlage_id) !== parseInt(this.systemId) && systemType !== 'anlageNebel' && systemType !== 'anlageVideo' && systemType === this.system.anlage_typ_key
				&& system.armingSchedule
				) {
				system.enabled = true
			}
			this.systems[system.anlage_id] = system
		})
	}

	onPageLoad() {
		this.objectId = this.$route.params.id
		// clear before load
		this.switchingTimes.interval = []
		this.switchingTimes.on = []
		this.switchingTimes.off = []
		this.systems = {}

		if (this.systemId) {
			getSwitchingTimesRequest(this.systemId).then((res: any) => {
				this.system = res.result.details
				this.filterSwitchingTimes(res.result)
			})
		}

		if (this.objectId) {
			getProjectByIdRequest(this.objectId).then((res: any) => {
				this.filterSystems(res.result)
			})
		}
	}

	mounted() {
		this.onPageLoad()
	}

}


import {Options, Vue} from "vue-class-component";


@Options({
    props: ['data']
})
export default class ObjectCard extends Vue {
	data: any
	errors: boolean = this.dataProps?.signals.errors !== null
    heinz: any = this.dataProps
    
    get dataProps(): any {
		console.log(this.data)
        return this.data
    }
	
	get project_name(): any {
		let name = ""
		if ( this.data.p_firma != "" ) {
			name = this.data.p_firma
		} else if ( this.data.p_name != "" ){
			name = 	this.data.p_vorname + '' + this.data.p_name
		}
		
		return name
	}
    
	showSystems() {
		this.$router.push({ name: "ObjectSystems", params: { id: this.dataProps.pid } })
	}
    
    showDetail(data: any): any {
        this.$emit('clicked', data)
    }
    
    goToObject():any {
        this.$router.push({name: "ObjectOverview", params: {id: this.dataProps.pid}})
    }
    
}

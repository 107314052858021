
import {Options, Vue} from "vue-class-component"

@Options({
	props: {
		max: Number,
		count: Number,
		value: String,
		unit: String,
		prefix: String,
		suffix: String,
		size: {
			type: Number,
			default: 30
		}
	}
})
export default class CounterCircle extends Vue {
	text: any
	count: any
	value: any
	unit: any
	prefix: any
	suffix: any
	
	get showText() {
		return this.text || this.count
	}
}

import { RouteRecordRaw } from 'vue-router'
import SignIn from "@/views/Auth/SignIn.vue";


export const routes: Array<RouteRecordRaw> = [
    {
        path: '/impersonate/:hash',
        name: 'Impersonate',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Impersonate.vue'),
        meta: {transitionName: 'fade'}
    },
    {
        path: '/sign-in',
        name: 'SignIn',
        component: SignIn,
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
        meta: {transitionName: 'fade'}
    },
    {
        path: '/user-data',
        name: 'UserData',
        component: () => import(/* webpackChunkName: "about" */ '../views/User/UserData.vue'),
        // meta: {transitionName: 'slide'}
    },
    {
        path: '/company',
        name: 'Company',
        component: () => import(/* webpackChunkName: "about" */ '../views/Company/Company.vue'),
        // meta: {transitionName: 'slide'}
    },
    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "about" */ '../views/User/ForgotPassword.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/confirmPasswordReset/:hash',
        name: 'ConfirmPasswordReset',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/ConfirmPasswordReset.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/confirmInvitation/:hash',
        name: 'ConfirmInvitation',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/ConfirmInvitation.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/confirmPhoneValidation/:hash',
        name: 'ConfirmPhoneValidation',
        component: () => import(/* webpackChunkName: "about" */ '../views/User/ConfirmPhoneValidation.vue'),
        meta: {transitionName: 'fade', noAuth: true}
    },
    {
        path: '/employees',
        name: 'Employees',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employee/Employees.vue'),
        // meta: {transitionName: 'slide'}
    },
    {
        path: '/employees/:id',
        name: 'EmployeeDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employee/EmployeeDetails.vue'),
        meta: {transitionName: 'slide', activeTab: 'Employees'}
    },
    {
        path: '/employees/new',
        name: 'EmployeeNew',
        component: () => import(/* webpackChunkName: "about" */ '../views/Employee/EmployeeDetails.vue'),
        meta: {transitionName: 'slide', activeTab: 'Employees'}
    },
    {
        path: '/login-data',
        name: 'LoginData',
        component: () => import(/* webpackChunkName: "about" */ '../views/User/LoginData.vue'),
        // meta: {transitionName: 'slide'}
    },
    {
        path: '/switching-times',
        name: 'SwitchingTimes',
        component: () => import(/* webpackChunkName: "about" */ '../views/SwitchingTime/SwitchingTimes.vue'),
        // meta: {transitionName: 'slide'}
    },
    {
        path: '/location-maps',
        name: 'LocationMaps',
        component: () => import(/* webpackChunkName: "about" */ '../views/LocationMap/LocationMaps.vue'),
        // meta: {transitionName: 'slide'}
    },
    {
        path: '/invoice',
        name: 'Invoice',
        component: () => import(/* webpackChunkName: "about" */ '../views/Invoice/Invoice.vue')
    },
    {
        path: '/contracts',
        name: 'Contracts',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contract/Contracts.vue')
    },
    {
        path: '/alerts',
        name: 'Alerts',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/Alerts.vue')
    },
    {
        path: '/alert/:id',
        name: 'AlertDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/Alert/AlertDetail.vue')
    },
    {
        path: "/objects",
        name: "Objects",
        component: () => import(/* webpackChunkName: "about" */ '../views/professional/Objects/Objects.vue'),
    },
    {
        path: "/object/:id",
        name: "ObjectOverview",
        component: () => import(/* webpackChunkName: "about" */ '../views/professional/Objects/ObjectOverview.vue'),
        children: [
            {
                path: "system/:system/contacts",
                name: "ObjectSystemContacts",
                component: () => import('../views/basic/Objects/ObjectSystemContacts.vue')
            },
            {
                path: "system/:system/switching-times",
                name: "ObjectSystemSwitchingTimes",
                component: () => import('../views/basic/Objects/ObjectSystemSwitchingTimes.vue')
            },
            {
                path: "system/:system/interventions",
                name: "ObjectSystemInterventions",
                component: () => import('../views/basic/Objects/ObjectSystemInterventions.vue')
            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    }
]

import { Vue } from 'vue-class-component'
import {getLocationMapsRequest} from "@/services/ObjectService";


export default class ObjectLocationMaps extends Vue {
    headline = "Lagepläne"
	locationMaps: any[] = []
	
	showLocationMap(image: string, name: any) {
		const images = []
		
		images.push({
			src  : image,
			width: '100px',
			height: '100px',
			opts : {
				caption : name,
				thumb   : image
			}
		})
		// @ts-ignore
		$.fancybox.open(images, {
			loop : false,
			
			buttons: [
				"zoom",
				//"share",
				"slideShow",
				"fullScreen",
				"download",
				"thumbs",
				"close"
			],
		});
	}
	
	beforeMount() {
		const projectId = this.$route.params.id
		
		getLocationMapsRequest(projectId).then((res: any) => {
			if ( res.result ) this.locationMaps = res.result
		})
	}
}

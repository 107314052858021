import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'

export function getProjectsOverviewRequest(data: any = {}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getProjectsByUserId',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function sendEmailToCustomerOnArming(data={}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/sendEmailToCustomerOnArming',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getAllSwitchingTimesRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getAllSwitchingTimesByUserId',
            method: "GET",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getContactsOverviewRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getContactsOverviewByUserId',
            method: "GET",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getAllLocationMapsRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getAllLocationMapsByUserId',
            method: "GET",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveObjektKennwortRequest(password: any, projectId: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/saveObjektKennwort',
            method: "POST",
            data: {password, project_id: projectId},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getContactInterventionObjectRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getContactInterventionObject',
            method: "POST",
            data: {projectId: id},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getProjectByIdRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getProjectById',
            method: "POST",
            data: {projectId: id},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSystemContactsByIdRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getSystemContacts',
            method: "POST",
            data: {systemId: id},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveAllSystemContactsRequest(id: any, contacts: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/saveSystemContacts',
            method: "POST",
            data: {id, contacts},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSwitchingTimesRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getSystemSwitchingTimes',
            method: "POST",
            data: {id: id},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getLocationMapsRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getProjectLocationMaps',
            method: "POST",
            data: {id: id},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getProjectComponentsRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getProjectComponents',
            method: "POST",
            data: {id: id},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveSystemTimesRequest(id: any, intervals: any, turnOnTimes: any, turnOffTimes: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/saveSystemSwitchingTimes',
            method: "POST",
            data: {anlageId: id, intervalle: intervals, turnOn: turnOnTimes, turnOff: turnOffTimes},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function setSystemArmingRequest(number: any, arming: boolean) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/setSystemArming',
            method: "POST",
            data: {systemNumber: number, arming},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function copyTimesToSystemsRequest(data: any = {}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/copyTimesToSystems',
            method: "POST",
            data: data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}


export function getProjectFilesRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getProjectFiles',
            method: "POST",
            data: { id: id },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSystemsByInterventionTypeRequest(type: any, objectId: any = null) {
    const data: any = {}
    if ( objectId !== null ) data.objectId = objectId
    data.type = type

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getSystemsByInterventionType',
            method: "POST",
            data: data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSystemsRequest() {

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getSystems',
            method: "POST",
            data: {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSystemByIdRequest(data: any = {}) {

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getSystemById',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function printSwitchingTimesOverviewRequest(args={}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/PDF/switchingTimesOverview',
            method: "POST",
            data: args || {} ,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSystemLogsRequest(data={}) {

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getSystemLogs',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function armingFailureRequest(data={}) {

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/armingFailure',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveProjectContactPersonRequest(data={}) {

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/saveProjectContactPerson',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveProjectContactPersonInterventionRequest(data={}){
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/saveProjectContactPersonIntervention',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveSystemRequest(data={}) {

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/saveSystem',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}


export function getSwitchingTimesLogsRequest(data={}) {

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getSwitchingTimeLogs',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSwitchingTimeExceptionsLogsRequest(data={}) {

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getSwitchingTimeExceptionsLogs',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getObjectContactPersonLogsRequest(data={}) {

    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getObjectContactPersonLogs',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveSystemSwitchingExceptionTimes(data={}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/saveSwitchingTimeException',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSystemSwitchingExceptions(data: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/getSystemSwitchingExceptions',
            data: data || {},
            method: "POST",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveAllSystemExceptions(data: any) {
    const postData = JSON.stringify({ data: data }); // cannot pass array of objects
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/saveAllSystemExceptions',
            data: postData || {},
            method: "POST",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function copyExceptionsToSystemsRequest(data: any = {}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Projects/copyExceptionsToSystems',
            method: "POST",
            data: data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

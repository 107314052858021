
import { Vue, Options} from 'vue-class-component'

import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import {
	checkForNotSynced,
	getNotSynchronizedInterventionContactsRequest,
	refreshToken
} from './services/BackgroundTasks';
import {getAccountsRequest} from "@/services/UserService";
import {getBreadcrumbNamesRequest, hideLoadingLayer} from "@/services/Globals";
import FabHelpButton from "@/components/FabHelpButton.vue";

const DEFAULT_TRANSITION = 'fade'
const DEFAULT_TRANSITION_MODE = 'out-in'

declare let $: any
declare let PNotify: any

@Options({
    components: {Navbar, Sidebar, FabHelpButton}
})
export default class App extends Vue {
    transitionName: string = DEFAULT_TRANSITION
    transitionMode: string = DEFAULT_TRANSITION
    accounts: any[] = []
	breadcrumbs: any = []
	
    get viewClass() {
        return this.$store.getters.viewClass
    }
    
	get user() {
		return this.$store.getters.user
	}
	
	showModal() {
		$('#modal_help').modal('show')
	}
	
	jumpBackToView(route: string) {
		this.$router.push({ name: route })
	}
	
	reloadAccounts() {
		if ( this.user.accessToken && this.user.accessToken !== "" ) {
			getAccountsRequest().then((res: any) => {
				let accounts: any = []
				if ( res.status === 'OK' && res.result.length ) {
					res.result.map((acc: any) => {
						switch (acc.accountType) {
							case "1":
								acc.icon = 'icon-home'
								break
							case "2":
								acc.icon = 'icon-office'
								break
							default:
								acc.icon = 'icon-user'
						}
						accounts.push(acc)
					})
				}
				this.accounts = accounts
			})
		}
	}
	
	keepAlive() {
		setInterval(() => {
			// check if logged in
			if ( this.user.refreshToken !== '' ) {
				// get new tokens with refresh token
				refreshToken(this.user.refreshToken).then((res: any) => {
					const user = res.result.user
					user.accessToken = res.result.accessToken
					user.refreshToken = res.result.refreshToken
					
					this.$store.dispatch('setUser', user)
				})
			}
		}, 60000) // every 1 minutes
	}
	
    beforeMount() {
        const viewClass = this.viewClass
        window.document.documentElement.className = viewClass
	    this.reloadAccounts()
    }
    
    created() {
        this.$router.beforeEach(async (to, from, next) => {
			hideLoadingLayer()
			
	        let breadcrumbs: any = to.meta.breadcrumbs
	        
	        if ( to.meta.breadcrumbs ) {
		        breadcrumbs.map((breadcrumb: any) => {
					breadcrumb.id = to.params[breadcrumb.param]
		        })
		
		        await getBreadcrumbNamesRequest(breadcrumbs).then((res: any) => {
			        breadcrumbs.map((breadcrumb: any) => {
				        if ( breadcrumb.param && res.result[breadcrumb.param] && res.result[breadcrumb.param] !== "" ) {
					        breadcrumb.name = res.result[breadcrumb.param]
				        }
			        })
			        this.breadcrumbs = breadcrumbs
			
			        to.meta.headline = breadcrumbs[breadcrumbs.length -1].name
		        })
	        } else {
		        this.breadcrumbs = []
	        }
	        
	        
	        
	        let metaTransitionName = to.meta.transitionName || from.meta.transitionName;
	        let transitionName = ''
	        let transitionMode = 'out-in'
	        if (metaTransitionName === 'slide') {
		        transitionName = 'slide';
		        transitionMode = ''
	        }
	
	        this.transitionName = transitionName || DEFAULT_TRANSITION;
	        this.transitionMode = transitionMode || DEFAULT_TRANSITION_MODE;
			
			if ( this.user.accessToken !== "" && to.name == "SignIn") {
				return next({ name: 'Dashboard' })
			}
	
	        if ( !to.meta?.noAuth && (!this.user.accessToken || this.user.accessToken === '') ) {
				// this.$router.push({ name: 'Employees' })
		        return next({ name: 'SignIn' })
		        // console.log('nerv mich nicht')
	        }
	        else {
				
				switch (to.name) {
					case "Contracts":
						if ( !this.user.rights.contracts?.read ) return next({ name: 'Forbidden' })
						break
					
					case "Invoice":
						if ( !this.user.rights.invoice?.read ) return next({ name: 'Forbidden' })
						break
					
					case "ObjectSystemSwitchingTimes":
						if ( !this.user.rights.arming?.write ) return next({ name: 'Forbidden' })
						break
					
					default:
						return next()
				}
				
				return next()
	        }
        });
		
		this.keepAlive()
	
	    this.connectSocket()
    }
	
	async connectSocket() {
		const user = await this.user
		
		this.$socket.on('disconnect', (err) => {
			console.log('disconnected socket because of: ', err)
		})
		
		this.$socket.on("connect_error", (err) => {
			console.log('connection broke caused by: ', err.message)
			const authToken = user.accessToken
			
			console.log(authToken)
			
			if (!authToken) return console.log('no auth token to reconnect')
			
			// not necessary
			// this.$socket.auth = { token: user.accessToken }
			
			this.$socket.connect()
		});
		
		this.$socket.auth = { token: user.accessToken }
		this.$socket.connect()
	}
    
    beforeCreate() {
        this.$store.commit('initialiseStore');
    }
	
	mounted() {
		if ( this.user.accessToken ) {
			checkForNotSynced()
			
			// TODO: remove <- only for test
			/*
			getNotSynchronizedInterventionContactsRequest().then((res: any) => {
				console.log(res)
				if ( !res.result ) return false
				
				// Custom top position
				var opts = {
					title: "Sie haben nicht übertragene Änderungen",
					// text: "Bitte klicken Sie hier um die Änderunge an die Anlage zu übertragen.",
					width: "auto",
					// cornerclass: "rounded-0",
					// addclass: "",
					// stack: { "dir1": "right", "dir2": "down", "push": "top", "spacing1": 1 },
					hide: false,
					confirm: {
						confirm: true,
						buttons: [
							{
								text: 'Jetzt Änderungen übertragen',
								addClass: 'btn btn-sm bg-danger-800 btn-block mt-3',
								click: (notice: any) => {
									notice.update({
										// addclass: 'stack-custom-bottom bg-primary border-primary text-white',
										// stack: {"dir1": "right", "dir2": "up", "spacing1": 1}
									})
									this.$router.push({ name: 'NotSynced' })
								}
							},
							{
								addClass: 'd-none'
							}
						]
					},
					buttons: {
						closer: false,
						sticker: false
					}
				};
				
				new PNotify(opts);
			})
			 */
		}
	}
}

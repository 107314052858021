import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'
import router from "@/router";

declare let PNotify: any
declare let $: any

export function refreshToken(refreshToken = '') {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/refreshToken',
            method: "POST",
            data: { refreshToken: store.getters.user.refreshToken || refreshToken},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getNotSynchronizedInterventionContactsRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getNotSynchronizedInterventionContacts',
            method: "POST",
            data: {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getNotSynchronizedInterventionsRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getNotSynchronizedInterventions',
            method: "POST",
            data: {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function testIntLog() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/test',
            method: "POST",
            data: {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export async function checkForNotSynced() {
    return; // ZR-123 
    $('.ui-pnotify').remove()

    await new Promise(((resolve: any) => {
        setTimeout(() => {
            resolve()
        }, 500)
    }))

    const contacts: any = await getNotSynchronizedInterventionContactsRequest()
    const interventions: any = await getNotSynchronizedInterventionsRequest()

    if ( !contacts.result && !interventions.result ) {
        return false
    }

    const opts = {
        title: "Sie haben nicht übertragene Änderungen",
        // text: "Bitte klicken Sie hier um die Änderunge an die Anlage zu übertragen.",
        width: "auto",
        // cornerclass: "rounded-0",
        // addclass: "",
        stack: { "dir1": "right", "dir2": "down", "push": "top", "spacing1": 1 },
        hide: false,
        confirm: {
            confirm: true,
            buttons: [
                {
                    text: 'Jetzt Änderungen übertragen',
                    addClass: 'btn btn-sm bg-danger-800 btn-block mt-3',
                    click: (notice: any) => {
                        notice.update({
                            // addclass: 'stack-custom-bottom bg-primary border-primary text-white',
                            // stack: {"dir1": "right", "dir2": "up", "spacing1": 1}
                        })
                        router.push({ name: 'NotSynced' })
                    }
                },
                {
                    addClass: 'd-none'
                }
            ]
        },
        buttons: {
            closer: false,
            sticker: false
        }
    };

    new PNotify(opts);
}


import {
	getFailAlertsRequest,
	getInvitedUsersRequest,
	getLastAlerts,
	getSupportContactsRequest
} from "@/services/Dashboard";
import {Options, Vue} from "vue-class-component";
import {
	_interruptedStepTypes,
	getInterruptedStep,
	hideLoadingLayer,
	showLoadingLayer, validateWeekday
} from "@/services/Globals";
import {
	armingFailureRequest, copyTimesToSystemsRequest,
	getSystemLogsRequest,
	getSystemsRequest,
	setSystemArmingRequest,
	sendEmailToCustomerOnArming
} from "@/services/ObjectService";
import { Countdown } from "@/services/Countdown";
import moment from "moment";
import Swal from "sweetalert2";
import CounterCircle from "@/components/CounterCircle.vue";
import {getEmployees} from "@/services/EmplyeeService";

declare let $: any

@Options({
	components: {CounterCircle}
})
export default class Dashboard extends Vue {
    unconfiguredSystems: any = [
        // {headline: "Anlage 1", text: "Hier steht Text oder so 1", class: "", icon: "fas fa-siren", systemId: 255, objectId: 901194},
        //{headline: "Anlage 2", text: "Hier steht Text oder so 2", class: "", icon: "fas fa-fire", systemId: 370, objectId: 901194},
        //{headline: "Anlage 3", text: "Hier steht Text oder so 3", class: "", icon: "fas fa-wifi", systemId: 371, objectId: 901194}
    ]
	interruptedSteps: any = []
    failAlerts: any = [
        {headline: "Alarm 1", text: "Hier steht Text oder so 1", class: "", icon: "fas fa-siren"},
        {headline: "Alarm 2", text: "Hier steht Text oder so 2", class: "", icon: "fas fa-fire"}
    ]
    unconfirmedUsers: any = []
	importedContacts: any = []
    lastAlerts: any = []
	systems: any = []
	systemLogs: any = []
	armingTime = null
	armingTimeStart = null
	armingText = ""
	armingSystem = ""
	armingRequestInterval: any = null
	tryArmingCounter = 0
	lastSystemRequest: any = moment().format('DD.MM.YYYY HH:mm')
	lastSystemLogsRequest: any = moment().format('DD.MM.YYYY HH:mm')
	countdown = new Countdown()
	
	get user() {
		return this.$store.getters.user
	}
	
	formatDateTime (datestring: string) {
		return moment(datestring).format('DD.MM.YYYY HH:mm')
	}
	
	goToStep(step: any) {
		this.$router.push({path: step.route})
	}
	
	goToSystemlogs() {
		this.$router.push({ name: "SystemLogs" })
	}
    
    showSystem(system: any) {
        this.$router.push({name: "ObjectSystemOverview", params: {id: system.objectId, system: system.systemId}})
    }
    
	async setArming(system:any, arming: boolean, id: any) {
		const number = system.anlage_nummer
		this.countdown.reset()
		this.armingSystem = system.anlage_typ+" "+system.anlage_bemerkung
		let maxTries = 2
		this.tryArmingCounter = 0
		
		await new Promise((resolve, reject) => {
			this.tryArming(number, arming, id, system.anlage_bemerkung, maxTries, resolve, reject)
		}).then(() => {
			// console.log('test läuft')
		}).catch((err: any) => {
			
			$('#modal_arming').modal('hide')
			const message = err ? err : '<p>Die Notrufleitstelle hat den Errichter so eben per Mail über dieses Problem informiert. Ihr Errichter wird sich mit Ihnen schnellstmöglich in Verbindung setzen.</p>';
			Swal.fire({
				icon: 'error',
				title: 'Anlage konnte nicht geschalten werden',
				html: message,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn btn-danger'
				}
			})
			
			armingFailureRequest({systemId: id, arming: arming}).then(() => {
				console.log('juhuhu')
			})
		})
		
	}
	
	tryArming(number: string, arming: boolean, id: any, anlage_bemerkung: any, loop: number, resolve: any, reject: any) {
		this.tryArmingCounter++
		
		this.armingText = arming ? "Scharf" : "Unscharf"
		
		if ( loop <= 0 ) {
			return reject()
		}
		loop = loop - 1
		
		new Promise((resol: any, rejec) => {
			setSystemArmingRequest(number, arming).then((res: any) => {
				const delay = res.result.verzoegerungZeit
				const messageSent = res.result.armingMessageSent
				const errorMessage = res.result.error_message
				
				this.armingTime = delay
				this.armingTimeStart = delay
				
				const countdown = new Countdown()
				countdown.setStart(delay)
				
				if (res?.result?.type === 'ajax' && messageSent) {
					Swal.fire({
						icon: 'success',
						title: this.armingText + ' erfolgreich durchgeführt',
						buttonsStyling: false,
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					const armingEventNumber = arming ? 200 : 100;
					this.sendEmailToUser(number, id, anlage_bemerkung, armingEventNumber);
					this.getSystems()
					this.getSystemLogs()
					$('#modal_arming').modal('hide');
					resol();
					return;
				}

				if (!messageSent) {
					clearInterval(this.armingRequestInterval)
					console.log(errorMessage)
					reject(errorMessage); // if message not sent by vodaFone or o2, no need to check again and again // if message not sent by vodaFone or o2, no need to check again and again
					return;
				}
				
				this.armingRequestInterval = setInterval(() => {
					getSystemLogsRequest({ filter: { system: [id], event: [100, 200]}, limit: 1 }).then((res: any) => {
						if ( res.result?.length > 0 ) {
							
							const armingEventNumber = arming ? 200 : 100
							if ( parseInt(res.result[0].eventId) == armingEventNumber ) {
								
								//write logic to send email to user for arming disarming
								this.sendEmailToUser(number, id, anlage_bemerkung, parseInt(res.result[0].eventId));

								this.getSystems()
								this.getSystemLogs()
								
								Swal.fire({
									icon: 'success',
									title: res.result[0].event+' erfolgreich durchgeführt',
									buttonsStyling: false,
									customClass: {
										confirmButton: 'btn btn-primary'
									}
								})
								
								countdown.stop()
								countdown.reset()
								clearInterval(this.armingRequestInterval)
								
								$('#modal_arming').modal('hide')
								
								resol()
							} else {
								console.log('noch nicht umgeschaltet')
							}
						}
					})
					
				}, 10000)
				
				countdown.counting((time: any) => {
					// console.log('ich bin counting')
					this.armingTime = time
				})
				
				countdown.onFinish(() => {
					console.log('ich bin fertig', this.armingRequestInterval)
					clearInterval(this.armingRequestInterval)
					rejec()
				})
				
				countdown.start()
				// this.countdown.start()
				
				$('#modal_arming').modal('show')
			})
		}).then(() => {
			resolve()
		}).catch(() => {
			this.tryArming(number, arming, id, anlage_bemerkung, loop, resolve, reject)
		})
		
	}

	sendEmailToUser(anlageNummer: any, anlageId: any, anlage_bemerkung: any, arming: number): void {
		const data = {
			anlageNummer,
			anlageId,
			anlage_bemerkung,
			arming,
		}
		sendEmailToCustomerOnArming(data).then((res: any) => {
			console.log(res)
			console.log("Email sent successfully")
		}).catch((error) => {
			console.log(error)
		})
	}
	
    showAlert(data: any) {
		console.log(data)
        this.$router.push({ name: 'AlertPreview', params: { id: data.id } })
    }
    
    showUser(data: any) {
		console.log(data)
	    this.$router.push({ name: 'EmployeeDetails', params: { id: data.id } })
    }
	
	goToAlerts() {
		this.$router.push({ name: 'Alerts' })
	}
	
	goToUsers() {
		this.$router.push({ name: 'Employees' })
	}
	
	goToImportedUsers() {
		this.$router.push({ name: 'ImportedContacts' })
	}
	
	goToSystems(system: any) {
		console.log(system)
		this.$router.push({ name: 'ObjectSystemOverview', params: {id: system.pid, system: system.anlage_id} })
	}
	
	getSystemLogs() {
		this.systemLogs = []
		this.lastSystemLogsRequest = moment().format('DD.MM.YYYY HH:mm')
		
		showLoadingLayer()
		
		getSystemLogsRequest().then((res: any) => {
			if ( res.result?.length > 0 ) {
				this.systemLogs = res.result
			}
			
			hideLoadingLayer()
		})
	}
	
	getSystems() {
		this.systems = []
		this.lastSystemRequest = moment().format('DD.MM.YYYY HH:mm')
		
		showLoadingLayer()
		
		getSystemsRequest().then((res: any) => {
			if ( res.result?.length > 0 ) {
				res.result.map((item: any) => {
					const nextArming = validateWeekday(item.nextSwitch.time, 0)
					item.nextSwitch.text = nextArming.weekday + " " + nextArming.startAsText
					
					item.last = "letzte Aktion: " + item.lastEventName + " " + moment(item.lastEventTime).format("DD.MM.YYYY HH:mm")
					item.next = "nächste Aktion: " + item.nextSwitch.text + " - " + item.nextSwitch.type
					
					if ( item.isTypeHikvision ) {
						item.last = ""
						item.next = "nächste Aktion: " + item.nextSwitch.text + " - " + (item.nextSwitch.type == "Scharf" ? 'kleinen Schwellwert einschalten' : 'kleinen Schwellwert ausschalten')
					}
					
					if ( item.lastEvent == null ) {
						item.last = ""
					}
					
					this.systems.push(item)
				})
				
			}
			
			hideLoadingLayer()
		})
	}
	
	beforeMount() {
		getLastAlerts().then((res: any) => {
			this.lastAlerts = []
			if ( res.status == 'OK' && res.result?.length > 0 ) {
				res.result.map((item: any) => {
					this.lastAlerts.push({
						id: item.alarm_id,
						headline: item.grund,
						text: item.details,
						class: "",
						icon: 'fal fa-siren'
					})
				})
			}
		})
		
		getFailAlertsRequest().then((res: any) => {
			this.failAlerts = []
			if ( res.status == 'OK' && res.result?.length > 0 ) {
				res.result.map((item: any) => {
					this.failAlerts.push({
						id: item.alarm_id,
						headline: item.grund,
						text: item.details,
						class: "",
						icon: 'fal fa-siren'
					})
				})
			}
		})
		
		getInterruptedStep().then((res: any) => {
			if ( res.result?.length > 0 ) {
				res.result.map((item: any ) => {
					this.interruptedSteps.push({
						id: item.id,
						headline: _interruptedStepTypes[item.type].name,
						text: _interruptedStepTypes[item.type].description,
						route: item.route
					})
				})
			}
		})
		
		getInvitedUsersRequest().then((res: any) => {
			if ( res.result?.length > 0 ) {
				res.result.map((item: any ) => {
					this.unconfirmedUsers.push({
						id: item.id,
						headline: item.firstName + ' ' + item.lastName,
						text: item.email,
						route: ""
					})
				})
			}
		})
		
		
		
		getEmployees({filter: {import: [1]}}).then((res: any) => {
			if ( res.result?.length > 0 ) {
				res.result.map((item: any ) => {
					this.importedContacts.push({
						id: item.id,
						headline: item.firstName + ' ' + item.lastName,
						text: item.email,
						route: ""
					})
				})
			}
		})
		
		
		getSupportContactsRequest().then((res: any) => {
			if ( res.result?.length > 0 ) {
				
				return true
			}
		})
		
		this.getSystems()
		
		this.getSystemLogs()
	}
	
	mounted() {
		/*
		this.countdown.counting((time: any) => {
			console.log('i am counting: ', time)
			// this.armingTime = time
		})
		
		this.countdown.onStop(() => {
			console.log('wurde gestoppt')
		})
		
		this.countdown.onFinish(() => {
			console.log('ist durchgelaufen')
			// clearInterval(this.armingRequestInterval)
			// this.tryArming(number, arming, id, loop, resolve, reject)
		})
		 */
	}
}

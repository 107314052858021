import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'

export function getUserData(): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/getUserData',
            method: "GET",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getAccountsRequest(): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/User/getAccounts',
            method: "GET",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function unlinkAccountRequest(hash: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/User/unlinkAccount',
            method: "POST",
            data:  { hash },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveUserDataRequest(data: any): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/User/saveUserData',
            method: "POST",
            data:  data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function changePhoneNumberRequest(data: any): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/User/changePhoneNumber',
            method: "POST",
            data: data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function changeInterventionNumberRequest(type: string, userId = null): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/User/changeInterventionNumber',
            method: "POST",
            data: { 
                type,
                userId
            },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function changeInterventionNumberByHashRequest(type: string, hash: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/User/changeInterventionNumberByHash',
            method: "POST",
            data: { type, hash },
            headers: {
                xsrf: conf.xsrf
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getNotificationConfigRequest(objectId: any): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/User/getNotificationConfig',
            method: "POST",
            data: {objectId},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}


export function saveNotificationConfigRequest(objectId: any, config={}): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/User/saveNotificationConfig',
            method: "POST",
            data: { objectId, config },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}
import { conf } from '../config/conf'
import axios from 'axios'
import store from '../store'

export function signInUser(username: string, password: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/signIn',
            method: "POST",
            data: {username: username, password: password},
            headers: {
                xsrf: conf.xsrf
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}


export function signOutUser(): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/signOut',
            method: "POST",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function confirmInvitationRequest(username: string, password: string, hash: string, pin: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/confirmInvitation',
            method: "POST",
            data: {username: username, password: password, hash: hash, pin: pin},
            headers: {
                xsrf: conf.xsrf
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function confirmPasswordResetRequest(username: string, password: string, hash: string, pin: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/confirmPasswordReset',
            method: "POST",
            data: {username: username, password: password, hash: hash, pin: pin},
            headers: {
                xsrf: conf.xsrf
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function resetPasswordRequest(email: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/resetPassword',
            method: "POST",
            data: { email: email },
            headers: {
                xsrf: conf.xsrf
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function impersonateUserRequest(data: any = {}): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/impersonateUser',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function linkAccountRequest(username: string, password: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/linkAccount',
            method: "POST",
            data: { username, password },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function switchAccountRequest(userId: number | string, userType: number | string): any {
    return new Promise((resolve: any, reject: any) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/switchAccount',
            method: "POST",
            data: {userId, userType},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function (res: any) {

            resolve(res.data)
        });

        request.catch(function (reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function confirmPhoneValidationRequest(pin: string, hash: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/confirmPhoneValidation',
            method: "POST",
            data: { hash, pin },
            headers: {
                xsrf: conf.xsrf,
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function addFirebasePushTokenRequest(data: any = {}): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Auth/saveFirebasePushToken',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {

            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}
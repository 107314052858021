import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'

import moment from "moment";

const _weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
const _weekdaysShort = ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO']

const _timeRanges = {
    0: { name: "Ganztätig" },
    1: { name: "Innerhalb der Anwesenheitszeiten" },
    2: { name: "Außerhalb der Anwesenheitszeiten" }
}

const _fileTypes: any = {
    pdf: { icon: 'icon-file-empty2', class: '', group: 'doc' },
    csv: { icon: 'icon-file-empty2', class: '', group: 'doc' },
    txt: { icon: 'icon-file-empty2', class: '', group: 'doc' },
    xlsx: { icon: 'icon-file-empty2', class: '', group: 'doc' },
    odt: { icon: 'icon-file-empty2', class: '', group: 'doc' },
    ods: { icon: 'icon-file-empty2', class: '', group: 'doc' },
    jpeg: { icon: 'icon-image5', class: '', group: 'img' },
    jpg: { icon: 'icon-image5', class: '', group: 'img' },
    png: { icon: 'icon-image5', class: '', group: 'img' },
    svg: { icon: 'icon-image5', class: '', group: 'img' },
    gif: { icon: 'icon-video-camera2', class: '', group: 'vid' },
    avi: { icon: 'icon-video-camera2', class: '', group: 'vid' },
    mov: { icon: 'icon-video-camera2', class: '', group: 'vid' },
    mp4: { icon: 'icon-video-camera2', class: '', group: 'vid' },
    mpg: { icon: 'icon-video-camera2', class: '', group: 'vid' },
    wav: { icon: 'icon-volume-medium', class: '', group: 'aud' }
}

const _interruptedStepTypes: any = {
    1: { name: "Interventions-Kontakte", description: "Abgebrochene Konfiguration der Interventionskontakte", icon: "", class: "" },
    2: { name: "Interventionen", description: "Abbgebrochene Konfiguration der Intervention", icon: "", class: "" }
}

const _userStatus: any = {
    0: { name: 'Eingeladen', icon: '', class: 'accent' },
    1: { name: 'Inaktiv', icon: '', class: 'secondary' },
    2: { name: 'Aktiv', icon: '', class: 'primary' },
    3: { name: 'gesperrt', icon: '', class: 'danger' },
    4: { name: 'gelöscht', icon: '', class: 'danger' }
}

function validateWeekday(start: number, end: number) {
    let index         = Math.floor(start/24)
    if ( index == 7 ) index = 0

    const weekday       = _weekdays[index]


    // const diff          = end - start

    const weekdayStart  = start - (24*index)
    const weekdayEnd    = end - (24*index)


    const startTime     = formatFloatToTime(weekdayStart)

    const endTime       = formatFloatToTime(weekdayEnd)
    const duration      = formatFloatToTime(weekdayEnd - weekdayStart)
    // const duration      =
    return {
        weekday: weekday,
        start: startTime,
        startAsText: startTime.hours+':'+startTime.minutes,
        end: endTime,
        endAsText: endTime.hours+':'+endTime.minutes,
        duration: duration,
        weekdayNumber: index
    }
}

function formatFloatToTime(arg: number) {
    let hours: string | number = Math.floor(arg)
    let minutes: string | number = arg >= 1 ? arg % hours * 60 : arg * 60
    minutes = Math.round(minutes)

    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes

    return {hours: hours, minutes: minutes}
}

function formatTimeToFloat(time: string) {
    const timeParts = time.split(':')
    const hours: number = parseInt(timeParts[0])
    const minutes: number = parseInt(timeParts[1])

    const float = hours + (minutes / 60)

    return float
}

function addInterruptedStep(route: string, type: number): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Interruption/addStep',
            method: "POST",
            data: { route: route, type: type },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

function clearInterruptedStep(route: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Interruption/clearStep',
            method: "POST",
            data: { route: route },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

function getInterruptedStep(): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Interruption/getSteps',
            method: "GET",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

function validatePhoneNumberRequest(number: string, areaCode: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Validation/validatePhoneNumber',
            method: "POST",
            data: { number, areaCode },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

function getPhoneCodesRequest(): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/System/getPhoneCodes',
            method: "GET",
            headers: {
                xsrf: conf.xsrf
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

function getBreadcrumbNamesRequest(breadcrumbs: any): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Breadcrumb/getBreadcrumbNames',
            method: "POST",
            data: { breadcrumbs },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

function getFilterRequest(view: string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Filter/getAll',
            method: "POST",
            data: { view: view },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

function getIconListRequest(): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Component/getIconList',
            method: "POST",
            data: {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

function getColorListRequest(): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/System/getColorList',
            method: "POST",
            data: {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

function stringToDate(dateString: string, lang: string) {
    if ( lang == 'de' ) {
        //
    }
    const date = moment(dateString).format('DD.MM.YYYY')
    return date
}

function showLoadingLayer() {
    const layer: any = document.getElementById('loading_layer')
    layer.classList.add('show')
}

function hideLoadingLayer() {
    const layer: any = document.getElementById('loading_layer')
    layer.classList.remove('show')
}

export {
    _fileTypes,
    _interruptedStepTypes,
    _weekdays,
    _weekdaysShort,
    _timeRanges,
    _userStatus,
    validateWeekday,
    formatFloatToTime,
    formatTimeToFloat,
    addInterruptedStep,
    clearInterruptedStep,
    getInterruptedStep,
    validatePhoneNumberRequest,
    getPhoneCodesRequest,
    getBreadcrumbNamesRequest,
    getFilterRequest,
    getIconListRequest,
    getColorListRequest,
    stringToDate,
    showLoadingLayer,
    hideLoadingLayer
}
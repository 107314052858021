import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import './main.scss'


import {io, Socket} from 'socket.io-client'
import {conf} from "@/config/conf";


const socket: Socket = io("wss://ieyai4Ie.nsl.software:5000", {
    auth: {
        token: store.getters.user.token
    },
    autoConnect: true
})

if ( process.env.NODE_ENV === 'production' ) {
    console.log = function () {
        //
    }
}


store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('store', JSON.stringify(state));
});


// Middleware for all api calls if not allowed
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    if ( error.response ) {
        if ( error.response.status == 403 || error.response.status == 401 ) {
            store.dispatch('resetUser')
            router.push({name: 'SignOut'})
        }
    } else if (error.request) {
        error.request.onabort = function (evt: any) {
            console.warn(evt)
        }
        error.request.onload = function (evt: any) {
            console.error(evt)
        }

    } else {
        console.error("Something bad happened")
    }

    return Promise.reject(error);
});

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $socket: Socket,
        $getFileUrl: string
    }
}

const application: any = createApp(App)
application.config.globalProperties.$socket = socket
application.config.globalProperties.$getFileUrl = conf.baseFileUrl;
application.config.performance = true;
application.use(store).use(router).mount('#app')

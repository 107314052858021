import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'

export function getEmployees(data = {}): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/getEmployees',
            method: "POST",
            data: data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getEmployeeById(id: number|string): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/getEmployeeById',
            method: "POST",
            data: {userId: id},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveEmployeeRequest(user: any) {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/saveEmployee',
            method: "POST",
            data: user,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getEmployeeGroupsRequest(data = {}): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/getGroups',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getEmployeeGroupsByEmployeeRequest(data = {}): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/getGroupsByEmployee',
            method: "POST",
            data: data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getEmployeeGroupByIdRequest(data: any): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/getGroupById',
            method: "POST",
            data: data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveGroupRequest(group: any) {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/saveGroup',
            method: "POST",
            data: group,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function checkEmailExistRequest(email: string) {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/checkEmailExist',
            method: "POST",
            data: {email},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function reInviteEmployeeRequest(userId: string) {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/reInviteEmployee',
            method: "POST",
            data: {userId},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getEmployeeLogsRequest() {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/getEmployeeLogs',
            method: "POST",
            data: {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getInterventionsAndContactsByUserId() {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Employee/getInterventionsAndContactsByUserId',
            method: "POST",
            data: {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}
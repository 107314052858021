import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'

export function getLastAlerts() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Dashboard/getLastAlerts',
            method: "POST",
            data: { limit: 2 },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getFailAlertsRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Dashboard/getFailAlerts',
            method: "POST",
            data: { limit: 2 },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getInvitedUsersRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Dashboard/getInvitedUsers',
            method: "POST",
            data: { limit: 2 },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSupportContactsRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Dashboard/getSupportContacts',
            method: "POST",
            data: {  },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}
const stopCountdown = new Event('stop')
const finishCountdown = new Event('finish')

export class Countdown {
    private _startTime: number
    private _time: number
    private _countdownInterval: any

    constructor() {
        this._startTime = 0
        this._time = 0
    }

    setStart(seconds: number) {
        this._startTime = seconds
        this._time = seconds
    }

    counting(callback: any) {
        const interval = setInterval(() => {
            callback(this._time)
            if ( this._time == 0 ) clearInterval(interval)
        }, 1000)
    }

    start() {
        this._countdownInterval = setInterval(() => {
            if ( this._time <= 0 ) return this.finish()
            this._time = this._time - 1
        }, 1000)
    }

    onStop(callback: any) {
        document.addEventListener('stop', callback)
    }

    onFinish(callback: any) {
        document.addEventListener('finish', callback)
    }

    stop() {
        clearInterval(this._countdownInterval)

        document.dispatchEvent(stopCountdown)
    }

    finish() {
        clearInterval(this._countdownInterval)

        document.dispatchEvent(finishCountdown)
    }

    reset() {
        this._startTime = 0
        this._time = 0

        clearInterval(this._countdownInterval)

        document.dispatchEvent(stopCountdown)
    }
}